<template>
  <HeaderComponent />
  <FooterComponent />
  <SideNav />
  <ToastComponent :visible="toastState.visible" :message="toastState.message" :type="toastState.type" :imageType="toastState.imageType"></ToastComponent> 
   <div class="container-fluid p-0">
    <div class="row login-bg m-0">
      <div class="rightContainer d-flex justify-content-end p-0 m-0" :style="popUpModuleStyles">
        <div class="signIn"></div>
        <div class="mx-auto d-flex flex-column justify-content-start align-items-center assessmentScoreScreen">
          <div v-if="showBreadcrumbs" class="row p-0 d-flex justify-content-start align-items-center" :style="breadcrumbStyles">
                <BreadcrumbComponent />
            </div>
            <!-- <ol class="breadcrumb" :style="breadcrumbStyles">
              <li @click="homeClick()">Home</li>
              <li class="active">Artifact Scoring</li>
            </ol>z -->
          <div class="row mx-auto d-flex justify-content-center align-items-center ms-5 me-1 mb-5 mt-4" style="width: 95%">
            <div class="headingTextBox d-flex align-items-center ms-5">Basic Framework Details</div>
            <div class="whiteBoxOne ms-5">
            <form class="row g-3 p-0">
              <div class="d-flex flex-column justify-content-between mt-3 p-0 ">
                <div class="d-flex justify-content-between align-items-center" style="width: 78.5%;">
                  <div class="col-4 d-flex flex-column">
                        <label for="clientName" class="form-label searchAssessmentLabel">Framework Name</label>
                        <div class="searchAssessPlaceHolder">
                          <input v-model="FrameworkModel.name" name="frameworkName" type="text" readonly class="fontSize150"/>
                        </div>
                  </div>
                  <div class="col-4 d-flex flex-column">
                        <label for="frameworkType" class="form-label searchAssessmentLabel">Framework Type</label>
                        <div class="searchAssessPlaceHolder">
                         <input v-model="FrameworkModel.type" name="frameworkType" type="text" readonly class="fontSize150"/>
                        </div>
                  </div>
                  <div class="col-4 d-flex flex-column">
                        <label for="statusInput" class="form-label searchAssessmentLabel">Status</label>
                        <div class="searchAssessPlaceHolder">
                          <input v-model="FrameworkModel.status" name="statusInput" id="statusInput" type="text" placeholder="All" readonly class="fontSize150"/>
                        </div>
                  </div>
                  <div class="col-4 d-flex flex-column">
                        <label for="clientName" class="form-label searchAssessmentLabel">Framework Description</label>
                        <textarea v-model="FrameworkModel.description" type="text" id="description" name="description" class="descriptionField mt-2" :title="textarea ? '': FrameworkModel.description" readonly></textarea>
<!--                         <div class="searchAssessPlaceHolder">
                          <input v-model="FrameworkModel.description" name="description" type="text" readonly class="fontSize150"/>
                        </div> -->
                  </div>
                </div>
              </div>
            </form>
            </div> 
           
            <div class="headingTextBox d-flex align-items-center ms-5 mt-5">
              <div class="d-flex justify-content-between align-items-center" style="width:100%">
                <span>View Framework Configurations </span>
                
            </div>
            </div>
            <div class="whiteBox ms-5 mb-5" style="padding: 1.5em 0.5em 2em 0em;" id="whiteBoxTwo" >             
              <div class="d-flex justify-content-start align-items-start fontSize150">
                <ul class="mainCategoryList" style="min-width:60%">
                  <div class="d-flex flex-column"> 
                    <li v-for="(category,catIndex) in categoryItems" :key="category.catId" class="subCategoryListItems" ref="categoryHeight">
                      <div class="d-flex align-items-center">
                        <div class="d-flex flex-column align-items-center">
                          <div @click="toggleCategory(category, catIndex,!isActive)" :class="{'categoryMaximiseIcon':!category.active,'categoryMinimiseIcon':category.active}" >
                            <div class="categoryLinkLine" :style="{height:categoryLineHeight[catIndex] + 'px'}">
                          </div>
                          </div>
                          <!-- <div class="d-flex flex-column align-items-center " v-for="category in categoryItems" :key="category.catId">
                          </div> -->
                        </div>
                        <div class="col-12 d-flex align-items-center ms-2">
                          <div class="col-4 d-flex align-items-center">
                            <span class="mainCategoryQuestionContent" id="activeCategory">
                              &nbsp; 
                              {{ category.name }}
                              &nbsp;
                            </span>
                          </div>
                          <div class="col-9 d-flex">
                            <ul class="col 12 subCategoryList">
                              <div class="d-flex  align-items-center">
                                <li v-for="subCategory in category.subCategory" :key="subCategory.id">
                                  <span class="subCategoryQuestionContent">
                                    &nbsp; {{ subCategory.name }} : {{ subCategory.qc.length }} 
                                  </span>
                                </li>
                                <div class="lineSeparator"></div>
                                <li>
                                  <span class="subCategoryQuestionContent">weight : {{ category.weight }}</span>
                                </li>
                              </div>
                              
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex align-items-center">
                        <ul class="col-12 subCategoryList">
                          <div class="d-flex flex-column ms-3" v-if="category.expanded">
                            <li v-for="(subCategory,subCatIndex) in category.subCategory" :key="subCategory.subCatId" style="margin-top: 2em;" ref="subCategoryHeight">
                              <div class="d-flex align-items-center mt-3">
                                <div @click="togglesSubCategory(subCategory,subCatIndex,catIndex)" :class="{'subCategoryMaximiseIcon':!subCategory.active,'subCategoryMinimiseIcon':subCategory.active}">
                                  <div class="linkLine" :style="{height:subCategoryLineHeight[subCatIndex] + 'px'}">
                                  </div>
                                </div>
                                <div class="d-flex justify-content-between align-items-center">
                                  <div class="d-flex align-items-center">
                                    <span class="questionsContent" style="padding-right: 2em;">
                                      &nbsp; {{ subCategory.name }}
                                    </span>
                                  </div> 
                                  <div class="lineSeparator">
                                  </div>
                                  <div class="d-flex align-items-center">
                                    <span class="questionsContent" style="padding-left: 2em;">
                                      Weight :{{ subCategory.weight }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <ul class="questionListItem" v-if="subCategory.expanded">
                                <div class="d-flex flex-column ms-4 mt-3">
                                  <li v-for="(questionList,qcIndex) in subCategory.qc" :key="questionList.id" style="margin-top: 1em;" ref="qcHeight">
                                    <div class="d-flex align-items-center">
                                      <div class="d-flex align-items-center">
                                        <div class="questionListIcon">
                                          <div class="qcLinkLine" :style="{height:qcLineHeight[qcIndex] + 'px'}">
                                          </div>
                                        </div>
                                        <span class="questionsContent">
                                          QC 0{{qcIndex+1}} :
                                        </span>
                                      </div>
                                      &nbsp; {{ questionList.name }}
                                    </div>
                                    <br>
                                    <div class="d-flex align-items-center ms-4">
                                      <span class="questionsContent">
                                        How to Evaluate Quality :
                                      </span>&nbsp; {{ questionList.evaluate }}
                                    </div>
                                    <br>
                                    <div class="d-flex align-items-center ms-4">
                                      <span class="questionsContent">
                                        Scoring Guidelines :
                                      </span>
                                      &nbsp; {{ questionList.guideline }}
                                    </div>
                                    <br>
                                    <div class="d-flex justify-content-between align-items-baseline ms-4">
                                      <div class="d-flex align-items-center">
                                        <span class="questionsContent">
                                          Weight :
                                        </span>
                                        &nbsp; {{ questionList.weight }}
                                      </div>
                                    
                                    </div>
                                  </li>
                                  <div class="linkLineEnd" :style="questionListLinkEnd"></div>
                                </div>
                              </ul>

                            </li>
                            <div class="linkLineEnd">
                                      
                            </div>
                          </div>
                        </ul>
                      </div>
                    </li>
                    <div class="linkLineEnd">
                                      
                    </div>
                  </div>
                </ul>             
             
              </div>
              <div class="d-flex justify-content-end align-items-end mt-5">
                <button type="reset" v-if="showCloseButton" class="btn btn-primary closeButton me-4" v-on:click="closeFrameworkScreen()">
                      Close
                  </button>
              </div>  
            </div>
          </div>
        </div>
      </div>
      <div :class="{'overlay':this.serverRequestOn}">
      </div>
      <div class="loading-bar-spinner" :class="{'spinner':this.serverRequestOn}">
      <div class="spinner-icon"></div>
                      </div>
    </div>
  </div>

  <!--  </div> -->
</template>
<script src="./viewFramework.controller.js"></script>
 
<style scoped src="./viewFramework.style.css"></style>
  