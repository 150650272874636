import axios from '../shared/interceptor.js';
import apiEndpoint from '@/app/services/uri-constants.js';

export default {

    async fetchArtifactListByAssessmentId(assessmentId) {
        const response = await axios.get(`${apiEndpoint.artifactListByAssessmentId}` + assessmentId)
        return response;

    },
    async saveArtifact(formdata) {
        const response = await axios.post(`${apiEndpoint.uploadArtifact}`, formdata)
        return response;

    },
    async updateArtifact(artifact) {
        return await axios.post(`${apiEndpoint.updateArtifact}`, artifact)
        .catch(function(error)
        {
            console.log('update artifact error',error);
        })
        

    },

    async updateArtifactStatus(artifactId, status) {
        const response = await axios.post(`${apiEndpoint.updateArtifactStatus}` +
            artifactId + '&status=' + status)
        return response;
    },
    async fetchArtifactById(artifactId) {
        const response = await axios.get(`${apiEndpoint.fetchArtifact}` + artifactId)
        return response;

    },
    async fetchFrameworkList() {
        const response = await axios.get(`${apiEndpoint.fetchFrameworkList}`)
        return response;
    },
    async deleteArtifact(artifactId) {
        const response = await axios.delete(`${apiEndpoint.deleteArtifact}` + artifactId)
        return response;
    },
    async findArtifactListByAssessmentId(assessmentId) {
        const response = await axios.get(`${apiEndpoint.findAllArtifactByAssessmentId}` + assessmentId)
        return response;
    }
}