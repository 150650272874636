/* import { showToast } from '../shared/toast/toast.controller.js';
import ToastComponent from '@/app/shared/toast/ToastComponent.vue';
import { toast } from '../shared/toast/toast.controller.js';
import userService from '@/app/login/login.service.js';
import  SharedService  from '../services/shared.service.js'; */

export default {
    name: 'Login',
/*     components: {
        ToastComponent
    },
    setup() {
        return {
            toastState: toast
        }
    }, */
    data() {
        return {
            UserList: [],
            User: {},
            emailId: "",
            password: "",
            errors: {
                emailId: "",
                password: ""
            },
            validateEmail: false,
            validatePassword: false,
            successMsg: "",
        };
    },
    methods: {
        validatingEmail(emailId) {
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.+-]+\.com$/;
            if (!regex.test(emailId)) {
                this.errors.emailId = "Please enter the Email in correct format";
                event.preventDefault();
            } else {
                this.validateEmail = true;
            }
            return this.validateEmail
        },
        validatingPassword(password) {
            /* const regexp = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/; */
            const regexp = /(?=.[a-z])(?=.{5,})/;

            if (!regexp.test(password)) {
                this.errors.password = "Please use valid characters for password";
                event.preventDefault();
            } else {
                this.validatePassword = true;
            }
            return this.validatePassword
        },

        clearEmail() {
            this.errors.emailId = " ";
            this.User.emailId = '';
        },

        clearPassword() {
            this.errors.password = " ";
            this.User.password = '';
        },
        signIn(){
          this.$router.push('/');
        }
    }
}