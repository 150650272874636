import FooterComponent from "@/app/shared/footer/FooterComponent.vue";
import HeaderComponent from "@/app/shared/header/HeaderComponent.vue";
import SearchAssessment from "@/app/AssessementDetails/SearchAssessment.vue";
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import SideNav from "@/app/shared/side-navigation/SideNav.vue";
import Chart from "chartjs-gauge"
import Charts from "chart.js/auto";
import { sharedVariable } from "../services/sharedData.js";
import dashboardService from '@/app/Dashboard/dashboard.service.js';
import assessmentService from '@/app/AssessementDetails/assessment.service.js';
import artifactService from "../ArtifactDetails/artifact.service.js";
import jsonData from "@/assets/data/cat-score.json";
import ToastComponent from '@/app/shared/toast/ToastComponent.vue';
import { showToast } from '../shared/toast/toast.controller.js';
import { toast } from '../shared/toast/toast.controller.js';
import sharedService from '../services/shared.service.js';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { toRaw, reactive } from 'vue';
/* import Score from "../model/score.model.js"; */
//  import * as pattern from "patternomaly";



export default {
    name: "FrameworkDashboard",
    components: {
        FooterComponent,
        HeaderComponent,
        SearchAssessment,
        ToastComponent,
        SideNav,
        BreadcrumbComponent
    },
    setup() {
        const loggedInRole = sharedService.getDataFromBrowser("role");
        sharedVariable.value = window.location.pathname;
        return {
            loggedInRole,
            toastState: toast,
        };
    },

    data() {
        return {
            toastState: toast,
            searchFilter: {
                display: true,
                showDetails: false,
                scoringScreen: false
            },
            showSemiCircleChart: true,
            whiteBoxVisible: true,
            showDonutChart: true,
            /* showSubCatDonutChart:false, */
            showSpiderChart: true,
            showBarChart: true,
            hideSearchSection: true,
            gaugeValue: 0,
            chartData: jsonData,
            filteredScoreLabel: [],
            backgroundColor: '',
            donut: {
                backgroundColor: ['#e86959', '#AC7DD1', '#eea616', '#26ce96'],
            },
            gradientColor:[],
            hoverColorforGradient:[],
            filteredBackgroundColor: [],
            displayChart: false,
            clientList: [],
            projectList: [],
            assessmentList: [],
            artifactTypeList: [],
            artifactListByType:[],
            ProjectModel: {},
            ClientModel: {},
            AssessmentModel: {},
            ArtifactModel: {},
            errors: {
                assessmentName: "",
                artifactType: "",
                artifactName: "",
            },
            breadcrumbStyles: {},
            routePath: '',
            artifactStatus: '',
            subCatDoughnutChart:null,
            catDonutChart:null,
            showForSubcatChart:false
        };
    },
    created() {
        // this.$store.commit('clearHistory');
        this.$store.commit('addToHistory', this.$route);
        this.breadcrumbStyles = {
            width: '82%'
        }
        if ((window.innerWidth > 1025) && (window.innerWidth <= 1280)) {
            this.subCatChartStyles={
                width: '315px !important',
                height: '250px !important'
            }
            this.subCategoryDonutChart={
                width: '250px !important',
                height: '250px !important'
            }
        }

        if ((window.innerWidth > 1281) && (window.innerWidth <= 1440)) {
            this.subCatChartStyles={
                width: '475px !important',
                height: '300px !important'
            }
            this.chartWrapper={
                width: '475px !important',
                height: '330px !important'
            }
            this.subCategoryDonutChart={
                width: '300px !important',
                height: '300px !important'
            }
        }
        if (this.$route.params.artifactId) {
            this.fetchArtifactScoreList(this.$route.params.artifactId);
            this.searchFilter.display = false;
            this.searchFilter.scoringScreen = true;
            this.routePath = '/artifactFrameworkDashboard'
            this.displayChart = true;
            this.ArtifactModel = sharedService.getData("artifact.model");
            this.fetchAssessment(this.ArtifactModel.assessmentId);
        } else if (this.$route.params.assessmentId) {
            this.routePath = '/frameworkDashboard'
            this.assessmentId = this.$route.params.assessmentId;
            this.searchFilter.showDetails = true;
            //this.displayChart = false;
            this.ClientModel = sharedService.getData("client.model");
            this.ProjectModel = sharedService.getData("project.model");
            this.AssessmentModel = sharedService.getData("assessment.model");
            if (!this.AssessmentModel) {
                this.fetchAssessment(this.assessmentId); 
              }
            this.selectedAssessment(this.assessmentId);
        } else {
            if (this.loggedInRole == 'frameworkAdmin') {
                this.onLoadClientName();
            } else {
                this.findAssessmentByUser();
            }
        }
    },
    beforeUnmount() {
        this.$store.commit("removeFromHistory", this.routePath);
    },
    methods: {
        buttonClick(artifactId) {
            // this.whiteBoxVisible = true;
            // this.showSemiCircleChart = true;
            // this.showDonutChart = true;
            // this.showSpiderChart = true;
            // this.showBarChart = true;
            // this.displayChart = true;
            // this.fetchArtifactScoreList(artifactId);
            if(this.loggedInRole=='frameworkAdmin' && this.ClientModel.id==undefined)
            {
                this.validateClient();
            }
            else if(this.loggedInRole=='frameworkAdmin' && this.ProjectModel.id==undefined)
            {
                this.selectedProject();
            }
            else if (this.AssessmentModel.id == undefined){
                this.assessmentValidation();
            } else if (this.artifactType == undefined){
                this.artifactTypeValidation();
            }else if (this.ArtifactModel.id == undefined){
                this.artifactNameValidation();
            } 
            else {
                this.displayChart=true;
                this.fetchArtifactScoreList(artifactId);
            }
            event.preventDefault();
        },
        assessmentValidation() {
            // this.ClientModel = {};
            if (this.AssessmentModel.id == undefined) {
                this.errors.assessmentName = "Please Select a Assessment";
            } else {
                this.errors.assessmentName = " ";
                this.artifactType="";
                this.ArtifactModel={};
                this.selectedAssessment(this.AssessmentModel.id);
                
            }
        },
        artifactTypeValidation() {
            // this.ClientModel = {};
            if (this.artifactType == undefined) {
                this.errors.artifactType = "Please Select a Artifact Type";
            } else {
                this.errors.artifactType = " ";
            }
        },
        artifactNameValidation() {
            // this.ClientModel = {};
            if (this.ArtifactModel.id == undefined) {
                this.errors.artifactName = "Please Select a Artifact Name";
            } else {
                this.errors.artifactName = " ";
            }
            event.preventDefault();
        },
        backToScoringScreen() {
            console.log("clicked")
            this.$router.push({
                name: "assessmentScoring",
                params: { assessmentId: this.AssessmentModel.id, assessmentName: this.AssessmentModel.assessmentName },
            });
        },
        async findAssessmentByUser() {
            const userId = sharedService.getDataFromBrowser("userId");
            const role = sharedService.getDataFromBrowser("role");
            const response = await assessmentService.fetchAssessmentByUserId(userId, role)
            if (response.data.header.responseMessage === "SUCCESS") {
                this.assessmentList = response.data.data;

                console.log("Assessement", this.assessmentList);
            } else {
                showToast('Failed to fetch Projects', 'error', 'validationerror')
            }
        },
        validateClient() {
            if (this.ClientModel.id == undefined) {
                this.errors.clientName = "Please Select a Client";
            } else {
                this.errors.clientName = " ";
                //this.selectedAssessment();    
                this.AssessmentModel = {};
                this.ArtifactModel = {};
                this.artifactType="";
                this.loadProjectByClient();
            }
        },
        selectedProject() {
            if (this.ProjectModel.id == undefined) {
                this.errors.projectName = "Please Select a Project";
            } else {
                this.errors.projectName = " ";
                this.assessmentList = [];
                this.artifactList = [];
                this.AssessmentModel = {};
                this.artifactType="";
                this.ArtifactModel = {};
                this.loadAssessmentByProjectId(this.ProjectModel);
            }
            // this.projectId = event.target.value
        },
        selectedAssessment(assessmentId) {
            if (this.loggedInRole == 'assessor' || this.loggedInRole == 'reviewer') {
                this.findArtifactByAssessmentIdAndUserId(assessmentId);
            } else {
                this.loadArtifactByAssessmentId(assessmentId);
            }    
        },
        loadArtifactNameByType(event) {
            this.validateArtifactType = true;
            this.ArtifactModel={};
            this.artifactListByType = [];
            for (let artifact of toRaw(this.artifactList)) {
                if (event.target.value === artifact.artifactType) {
                    this.artifactListByType.push(reactive(artifact));
                }
            }
            this.errors.artifactType=" ";
        },

        async onLoadClientName() {
            const response = await assessmentService.fetchClient();
            if (response.data.header.responseMessage === "SUCCESS") {
                this.clientList = response.data.data;
                this.inputReadOnly = {
                    outline: "none !important",
                    border: "none !important",
                };
                console.log(this.clientList);
            } else {
                showToast("Failed to fetch Clients", "error", "validationerror");
            }
        },
        async fetchAssessment(assessmentId) {
            const response = await assessmentService.fetchAssessmentById(assessmentId);
            if (response.data.header.responseMessage === "SUCCESS") {
                this.AssessmentModel = response.data.data;
                sharedService.setData('assessment.model', this.AssessmentModel);
              //  this.fetchProjectById(this.AssessmentModel.projectId);
            } else {
                showToast('Failed to fetch Assessment Details', 'error', 'validationerror')
            }
        },
        async fetchProjectById() {
            const response = await assessmentService.fetchProjectById(
                this.AssessmentModel.projectId
            );
            if (response.data.header.responseMessage === "SUCCESS") {
                this.ProjectModel = response.data.data;
                sharedService.setData("project.model", this.ProjectModel);
                const ProjectResponse = await assessmentService.fetchClientById(
                    this.ProjectModel.clientId
                );
                if (ProjectResponse.data.header.responseMessage === "SUCCESS") {
                    this.ClientModel = ProjectResponse.data.data;
                    sharedService.setData("client.model", this.ClientModel);
                } else {
                    showToast("Failed to fetch Projects", "error", 'validationerror');
                }
            } else {
                showToast("Failed to fetch Projects", "error", 'validationerror');
            }


        },
        async loadProjectByClient() {
            this.validateClientName = true;
            const response = await assessmentService.fetchProjectByClientId(
                this.ClientModel.id
            );
            if (response.data.header.responseMessage === "SUCCESS") {
                this.projectList = response.data.data;
                console.log(this.projectList);
            } else {
                showToast("Failed to fetch Projects", "error", 'validationerror');
            }
            sharedService.setData("client.model", this.ClientModel);
            this.clientError = "";
            this.projectError = "";
        },
        async loadAssessmentByProjectId(selectedProject) {
            const response = await assessmentService.fetchAssessmentByProjectId(
                selectedProject.id
            );
            console.log("response", response);
            if (response.data.header.responseMessage === "SUCCESS") {
                this.assessmentList = response.data.data;

                console.log("Assessement", this.assessmentList);
                sharedService.setData("project.model", selectedProject);
            } else {
                showToast("Failed to fetch Assessment for the selected Project", "error", 'validationerror');
            }
        },
        async loadArtifactByAssessmentId(assessmentId) {
            const response = await artifactService.findArtifactListByAssessmentId(assessmentId);
            if (response.data.header.responseMessage === "SUCCESS") {
                this.artifactList = response.data.data;
                this.artifactTypeList = [...new Set(this.artifactList.map(artifact => artifact.artifactType))]
                //sharedService.setData("assessment.model", assessment);
            } else {
                showToast("Failed to fetch Artifacts for the selected Assessment", "error", 'validationerror');
            }
        },
        async findArtifactByAssessmentIdAndUserId(assessmentId) {
            const userId = sharedService.getDataFromBrowser("userId");
            const response = await assessmentService.findArtifactByAssessmentIdAndUserId(assessmentId, userId)
            if (response.data.header.responseMessage === "SUCCESS") {
                this.artifactList = response.data.data
                this.artifactTypeList = [...new Set(this.artifactList.map(artifact => artifact.artifactType))]
                //sharedService.setData("assessment.model", assessment);
            } else {
                showToast('Failed to fetch Projects', 'error', 'validationerror')
            }
        },
        async fetchArtifactScoreList(artifactId) {
            if (artifactId != '') {
                const response = await dashboardService.fetchArtifactScoreList(artifactId)
                if (response) {
                    // if (response.data.header.responseMessage === "SUCCESS") {
                    console.log("response", response);
                    this.Score = response.data
                    /* console.log("SCORES RECORDED---->", this.Score.categoryList[0].subCategoryList); */
                    if(this.Score.rqdscore<=0)
                    {
                        this.displayChart = false;
                        showToast('No Score available for selected Artifact', 'error', 'validationerror')
                    }
                    else
                    
                        this.createSemiDonutChart();
                        this.createDonutChart(this.Score.categoryList);
                        this.createSpiderChart(this.Score.categoryList);
                        this.createBarChart(this.Score.categoryList);
                    


                } else {
                    showToast('No Score found ', 'error', 'validationerror')
                }
            } else {
                showToast('Failed to fetch Dashboard score', 'error', 'validationerror')
            }
        },
        async downloadPdfFile() {
            if(this.ArtifactModel.id){
                const showLegend = document.getElementById("showLegend");
                const canvas = await html2canvas(showLegend);
                const donutChart = document.getElementById("donutChart");
                const semiDonutChart = document.getElementById("semiDonutChart");
                const spiderChartResponsive = document.getElementById("spiderChartResponsive");
                const barChart = document.getElementById("barChart");
                // const canvas = await html2canvas(semiDonutChart);

                const pdf = new jsPDF();
                const topMargin = 10;

                //adding title 
                const pageWidth = pdf.internal.pageSize.width;
                const titleText = "Assessment Dashboard";
                const fontSize = 15;
                const textWidth = pdf.getStringUnitWidth(titleText) * fontSize/pdf.internal.scaleFactor;
                const titleX = (pageWidth - textWidth)/2;
                pdf.setFontSize(fontSize);
                pdf.setFont('helvetica','bold');
                pdf.text(titleText,titleX,topMargin);

                //adding neccessary data
                const assessmentName = this.AssessmentModel.assessmentName
                const clientName = this.ClientModel.clientName
                const artifactName = this.ArtifactModel.artifactName
                const projectName = this.ProjectModel.projectName
                const documentName = this.ArtifactModel.attachment
                const documentType = this.ArtifactModel.artifactType
                const docAttachment = this.ArtifactModel.attachmentExtn
                
                pdf.setFontSize(9);
                pdf.setFont('helvetica','normal');
                pdf.text(`Assessment Name: ${assessmentName}`, 20,30);
                pdf.text(`Client Name: ${clientName}`, 20,20);
                pdf.text(`Artifact Name: ${artifactName}`, 110,30);
                pdf.text(`Project Name: ${projectName}`, 110,20);
                pdf.text(`Uploaded Document Name:\n${documentName}.${docAttachment}`,20,40);
                pdf.text(`Document Type: ${documentType}`,110,40);

                //adding lables
                const gaugeValue = this.gaugeValue;
                pdf.setFontSize(14);
                pdf.text(`Overall RQD Score: ${gaugeValue}%`,23,118);
                pdf.setFontSize(11);
                pdf.setFont('helvetica','bold');
                pdf.text('RQD Score',20,55);
                pdf.text('Category Score',110,55);
                pdf.text('Subcategory Score',20,140);
                
                //creating semiDonut chart
                pdf.setLineWidth(0.1);
                pdf.line(20,60,100,60);
                pdf.addImage(
                    canvas.toDataURL("image/png"),
                    "PNG",
                    6, 62, 50, 11     
                );
                pdf.addImage(
                    semiDonutChart.toDataURL("image/png"),
                    "PNG",
                    20, 60, 60, 65
                );

                //creating Donut chart
                pdf.setLineWidth(0.1);
                pdf.line(110,60,200,60);

                pdf.addImage(
                    donutChart.toDataURL("image/png"),
                    "PNG",
                110, 60, 60, 60
                );

                //creating Spider chart
                pdf.setLineWidth(0.1);
                pdf.line(20,144,200,144);
                pdf.addImage(
                    spiderChartResponsive.toDataURL("image/png"),
                    "PNG",
                20, 145, 100, 100
                );

                //creating Bar chart
                pdf.addImage(
                    barChart.toDataURL("image/png"),
                    "PNG",
                115, 145, 70, 60
                );
                new jsPDF('l', 'mm', 'a4');
                pdf.save("dashboard.pdf");
            }
            else{
                
                showToast('Select the artifact to export', 'error', 'validationerror')
            }
        },
        createSemiDonutChart() {
            var semiDonutChart = new Chart("semiDonutChart", {
                type: "gauge",
                data: {
                    labels: ["Develop", "Sufficient", "Optimal"],
                    datasets: [{
                        value: this.Score.rqdscore,
                        minValue: 0,
                        data: [40, 80, 100],
                        backgroundColor: ["#e86959", "#eea616", "#26ce96"],
                        borderWidth:0,
                    }, ],
                },
                options: {
                    needle: {
                        radiusPercentage: 2.5,
                        widthPercentage: 4,
                        lengthPercentage: 85,
                        color: "#B2B2B2",
                    },
                    valueLabel: {
                        display: false,
                        formatter: (value) => {
                            return "$" + Math.round(value);
                        },
                        plugins: {
                            legend: {
                                display: true,
                                // labels: {
                                //     color: 'rgb(255, 99, 132)'
                                // }
                            },
                        },
                        color: "rgba(255, 255, 255, 1)",
                        backgroundColor: "rgba(0, 0, 0, 1)",
                        borderRadius: 5,
                        padding: {
                            top: 10,
                            bottom: 10,
                        },
                    },
                },
            });
            this.gaugeValue = semiDonutChart.data.datasets[0].value;
            console.log("=========", this.gaugeValue);
            semiDonutChart;
        },
        createDonutChart(catList) {
            const labels = catList.map((item) => item.catName);
            const values = catList.map((item) => item.score);
            const catID = catList.map((item)=>item.catId)     

            if(this.catDonutChart){
                this.catDonutChart.destroy();
            }
            //here the const variable ctx represents the 2D drawing context of an HTML5 canvas element with ID or reference "chart".
            const ctx = this.$refs.chart.getContext("2d");
            this.catDonutChart=new Charts(ctx, {
                type: "doughnut", // Choose the appropriate chart type
                data: {
                    labels: labels,
                    datasets: [{
                        label: "Scores",
                        data: values,
                        /* data: [25, 25, 25, 25], */
                        backgroundColor: this.donut.backgroundColor,
                        hoverBackgroundColor: ['#e86959', '#AC7DD1', '#eea616', '#26ce96'],
                        borderColor: "#d1d1d1",
                        borderWidth: 1,
                        datalabels: {
                            display: false,
                        },
                    },],
                },
                options: {
                    rotation: 270,
                    circumference: 360,
                    cutout: "50%",
                    plugins: {
                        legend: {
                            display: true,
                            position: "top",
                            align: "start",
                        },
                    },
                    responsive: true,
                    maintainAspectRatio: true,
                    hover: {
                        mode: 'nearest',
                        intersect: true,
                    },
                    elements: {
                        arc: {
                            borderWidth: 2,
                            hoverBorderWidth: 20,
                            hoverBorderColor: ['rgba(232, 105, 89, 0.3)', 'rgba(172, 125, 209, 0.3)', 'rgba(238, 166, 22, 0.3)', 'rgba(38, 206, 150, 0.3)'],
                            borderRadius: 0,
                        }
                    },
                    onHover: (event, elements, chart) => {
                        if (elements.length) {
                            chart.canvas.style.cursor = 'pointer';
                            chart.canvas.style.zIndex ='2'
                        }
                        else {
                            chart.canvas.style.cursor = 'default';
                        }
                    },
                    onClick: (event, elements) => {
                        if (elements.length > 0) {
                            const clickedIndex = elements[0].index;
                            this.clickedSegmentLabel = labels[clickedIndex];
                            this.clickedCatID = catID[clickedIndex]
                            let selectedSubCatList = [];
                            this.Score.categoryList.forEach((category) => {
                                    if(category.catId == this.clickedCatID){
                                    selectedSubCatList.push(...category.subCategoryList);
                                }
                            });
                            this.createNewChart(selectedSubCatList, elements[0].element.options.backgroundColor)
                            }
                    },
                },
            })
        },
        createNewChart(selectedSubCatList,selectedSubCatColor){
            this.createSubCatDonutChart(selectedSubCatList,selectedSubCatColor)
        },
        calculatedGradientColor(subcatLength,baseColorRgb){
            const gradientPercentage = 5;
            baseColorRgb = this.hexToRgb(baseColorRgb)
            console.log("Values inside for Loop", baseColorRgb)
            const gradientColorRgb = {
                r:Math.min(baseColorRgb.r + gradientPercentage, 255),
                g:Math.min(baseColorRgb.g + gradientPercentage, 255),
                b:Math.min(baseColorRgb.b + gradientPercentage, 255),
            }            
            var color=0.0;
            for (let i = 0; i < subcatLength; i++) {
                color=color+0.4
                this.gradientColor[i] = `rgba(${gradientColorRgb.r},${gradientColorRgb.g},${gradientColorRgb.b},${color})`;
            }
            console.log("PRINT GRADIENT COLOR after",this.gradientColor)
            return this.gradientColor
        },
        hexToRgb(hex) {
            const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
            hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
            let result = []
            result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            console.log("RESULT", result)
            return result? {r: parseInt(result[1], 16),g: parseInt(result[2], 16),b: parseInt(result[3], 16),}: null;
        },
        createSubCatDonutChart(subCatList,selectedSubCatColor) {
            this.showForSubcatChart = true;
            const subCatNameLabel = subCatList.map((item) => item.subCatName);
            const subCatScoreValues = subCatList.map((item) => item.score);
            this.gradientColor = this.calculatedGradientColor(subCatList.length, selectedSubCatColor);
            console.log("FINALLY GRADIENT COLOR fo subcat chart IS--->", [...this.gradientColor])
            if(this.subCatDoughnutChart){
                this.subCatDoughnutChart.destroy();
            }
            //here the const variable ctx represents the 2D drawing context of an HTML5 canvas element with ID or reference "chart".
            const ctx =  this.$refs.subCatChart.getContext("2d");
            
            this.subCatDoughnutChart = new Charts(ctx, {
                type: "doughnut", // Choose the appropriate chart type
                data: {
                    labels: subCatNameLabel,
                    datasets: [{
                        label: "subCatScore",
                        data: subCatScoreValues,                        
                        backgroundColor: this.gradientColor,
                        hoverBackgroundColor: this.gradientColor,
                        borderColor: this.gradientColor,
                        borderWidth: 0,
                        datalabels: {
                            display: false,
                        },
                    },],
                },
                options: {
                    rotation: 270,
                    circumference: 360,
                    cutout: "50%",
                    plugins: {
                        legend: {
                            display: true,
                            position: "top",
                            align: "start",
                        },
                    },
                    responsive: true,
                    maintainAspectRatio: true,
                    onHover: (event, elements, chart) => {
                        if (elements.length) {
                            chart.canvas.style.cursor = 'pointer';
                        }
                        else {
                            chart.canvas.style.cursor = 'default';
                        }
                    },
                    //commented for Hover styles
/*                     hover: {
                        mode: 'nearest',
                        intersect: true,
                    },
                    elements: {
                        arc: {
                            borderWidth: 2,
                            hoverBorderWidth: 20,
                            hoverBorderColor: ['rgba(232, 105, 89, 0.3)', 'rgba(172, 125, 209, 0.3)', 'rgba(238, 166, 22, 0.3)', 'rgba(38, 206, 150, 0.3)'],
                            borderRadius: 0,
                        }
                    },
                    onHover: (event, elements, chart) => {
                        if (elements.length) {
                            chart.canvas.style.cursor = 'pointer';
                        }
                        else {
                            chart.canvas.style.cursor = 'default';
                        }
                    }, */
                },
            })
        },
        createSpiderChart(catList) {
            let nameScoreList = [];
            catList.forEach((ele) => {
                nameScoreList.push(...ele.subCategoryList);
            });
            console.log("nameScoreList--------------->", nameScoreList);

            let subCatNameLabel = nameScoreList.map((ele) => ele.subCatName);
            let scoreLabel = nameScoreList.map((ele) => ele.score);

            let spiderchartDataFirst = 40;
            let spiderchartDataSecond = 70;
            let spiderchartDataThird = 100;

            let redDataArray = [];
            let yellowDataArray = [];
            let greenDataArray = [];

            for (let i = 0; i < scoreLabel.length; i++) {
                redDataArray[i] = spiderchartDataFirst;
                yellowDataArray[i] = spiderchartDataSecond;
                greenDataArray[i] = spiderchartDataThird;
            }
            if(this.spiderSubCatChart){
                this.spiderSubCatChart.destroy();
            }
            //here the const variable ctx represents the 2D drawing context of an HTML5 canvas element with ID or reference "chart".
            const ctx = this.$refs.spiderChart.getContext("2d");
            this.spiderSubCatChart=new Charts(ctx, {
                type: "radar", // Choose the appropriate chart type
                data: {
                    labels: subCatNameLabel,
                    datasets: [{
                            label: "Artifact 1",
                            data: scoreLabel,
                            backgroundColor: "rgba(75,192,192,0.01)",
                            borderColor: "rgba(0,51,204,1)",
                            borderWidth: 1,

                        },
                        {
                            label: "Develop",
                            data: redDataArray,
                            backgroundColor: "#e86959",
                            borderColor: "transparent",
                            borderWidth: 1,
                            pointRadius: 0,
                            fill: true,
                        },
                        {
                            label: "Sufficient",
                            data: yellowDataArray,
                            backgroundColor: "#eea616",
                            borderColor: "transparent",
                            pointRadius: 0,
                            fill: true,
                        },
                        {
                            label: "Optimal",
                            data: greenDataArray,
                            backgroundColor: "#26ce96",
                            borderColor: "transparent",
                            pointRadius: 0,
                            fill: true,
                        },
                    ],
                },
                options: {
                    scales: {
                        r: {
                            min: 0, // MIN
                            max: 100, // MAX
                            beginAtZero: true,
                            angleLines: {
                                z: 1,
                                color: "rgba(178, 178, 178, 0.45)",
                            },
                            grid: {
                                color: "rgba(178, 178, 178, 0.45)",
                                z: 1,
                            },
                            pointLabels: {
                                font: {
                                    weight: "bold",
                                    size: 12,
                                },
                            },
                            ticks: {
                                z: 1,
                                color: "black",
                                stepSize: 10,
                                callback: (value) => {
                                    return value + "%";
                                },
                                backdropColor: "rgba(0,0,0,0)",
                            },
                        },
                    },
                    responsive: true,
                    plugins:{
                        legend:{
                            display:true,
                            labels:{
                                filter:function(legendItem, ctx){
                                    return ctx.datasets[legendItem.datasetIndex].label !== 'Artifact 1';
                                }
                            }
                        }
                    }
                },
            });
/*             console.log("SPIDER CHART VALUES", ctx)
            this.ctx.datasets[0].legend = false; */
        },
        createBarChart(catList) {
            let nameScoreList = [];
            this.filteredScoreLabel=[];
            catList.forEach((ele) => {
                /* nameScoreList.push(...ele.subCategoryList); */
                nameScoreList.push(...ele.subCategoryList);
            });
            console.log("nameScoreList", nameScoreList);
           // let barSubCatChart=null;
            let subCatNameLabel = nameScoreList.map((ele) => ele.subCatName);
            let scoreLabel = nameScoreList.map((ele) => ele.score);
            for (let i = 0; i < scoreLabel.length; i++) {
                let remainingValue = scoreLabel[i]
                let totalValue = 100 - remainingValue;
                if (totalValue < 100) {
                    this.filteredScoreLabel.push(totalValue)
                    console.log("filterdata", this.filteredScoreLabel);
                }
            }
            for (let i = 0; i < scoreLabel.length; i++) {
                let rangeValue = scoreLabel[i];
                if ((rangeValue > 0) && (rangeValue <= 40)) {
                    this.backgroundColor = "#e86959";
                    this.filteredBackgroundColor.push(this.backgroundColor);
                } else if ((rangeValue > 40) && (rangeValue <= 70)) {
                    this.backgroundColor = "#eea616";
                    this.filteredBackgroundColor.push(this.backgroundColor);
                } else if ((rangeValue > 70) && (rangeValue <= 100)) {
                    this.backgroundColor = "#26ce96";
                    this.filteredBackgroundColor.push(this.backgroundColor);
                }

            }
            if(this.barSubCatChart){
                this.barSubCatChart.destroy();
            }
            
            //here the const variable ctx represents the 2D drawing context of an HTML5 canvas element with ID or reference "chart".
            const ctx = this.$refs.barChart.getContext("2d");
            this.barSubCatChart=new Charts(ctx, {
                type: "bar", // Choose the appropriate chart type
                data: {
                    labels: subCatNameLabel,
                    datasets: [{
                            data: scoreLabel,
                            backgroundColor: this.filteredBackgroundColor,
                            borderRadius: 15,
                        },
                        {
                            data: this.filteredScoreLabel,
                            borderRadius: 15,
                            backgroundColor: "#B2B2B2",
                            datalabels: {
                                display: false,
                            },
                        },
                    ],
                },
                options: {
                    indexAxis: "y",
                    scales: {
                        x: {
                            stacked: true,
                            display: false,
                            beginAtZero: true,
                            max: 100,
                            grid: {
                                display: false,
                            },
                        },
                        y: {
                            stacked: true,
                            grid: {
                                display: false,
                            },
                        },
                    },
                    plugins: {
                        datalabels: {
                            display: true,
                            color: "#000",
                            anchor: "center",
                            align: "end",
                            font: {
                                weight: "bold",
                            },
                            formatter: Math.round,
                        },
                        legend: {
                            display: false,
                        },
                    },
                },
            });
        },

    },
};