import axios from '../shared/interceptor.js';
import apiEndpoint from '@/app/services/uri-constants.js';
import { showToast } from "../shared/toast/toast.controller.js";

export default {
    async fetchFrameworkList() {
        const response = await axios.get(`${apiEndpoint.fetchFrameworkList}`)
        return response;
    },

    async createFramework(framework) {
        return axios.post(`${apiEndpoint.createFramework}`, framework)
            .catch(function(error) {

                console.log('error---', error);
            });

    },

    async createFrameworkRecord(framework) {
        return axios.post(`${apiEndpoint.createFrameworkRecord}`, framework)
            .catch(function(error) {

                console.log('error---', error);
            });

    },

    async saveAllFrameworkDetails(framework) {
        return axios.post(`${apiEndpoint.saveAllFrameworkDetails}`, framework)
            .catch(function(error) {

                console.log('error---', error);
            });

    },

    async editFramework(frameworkId) {
        return axios.get(`${apiEndpoint.configureFramework}` + frameworkId).
        catch(function(error) {
            if (error.code == 'ERR_BAD_RESPONSE') {
                showToast('Failed to fetch the Framework', 'error', 'validationerror');
            } else {
                showToast('Failed to fetch the Framework ', 'error', 'validationerror');
            }
        });
    },

    async updateFrameworkStatus(frameworkId, status) {
        return axios.post(`${apiEndpoint.updateFrameworkStatus}` +frameworkId + '&status=' + status)
         
    },

    

    async deleteFramework(frameworkId) {
        return axios.delete(`${apiEndpoint.deleteFramework}` + frameworkId)
            .catch(function(error) {
                console.log('error---', error);
            });

    },
    async copyFramework(frameworkId) {
        return axios.get(`${apiEndpoint.copyFramework}` + frameworkId)
            .catch(function(error) {
                console.log('error---', error);
            });

    },

    async deleteFrameworkRecord(id, type) {
        return axios.delete(`${apiEndpoint.deleteFrameworkRecord}` + id + '&type=' + type)
            .catch(function(error) {
                console.log('error---', error);
            });

    },

    async viewFramework(currentId){
        const response= await axios.get(`${apiEndpoint.viewFramework}` + currentId) 
        return response       
    }


}