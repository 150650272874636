/* import HeaderLanding from '@app/shared/header/HeaderLanding.vue'; */
import FooterComponent from '@/app/shared/footer/FooterComponent.vue';
import HeaderComponent from '@/app/shared/header/HeaderComponent.vue';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import LandingPage from '@/app/landingpage/LandingPage.vue';
import SharedService from '../services/shared.service.js';
import { sharedVariable } from '../services/sharedData.js';

export default {
    name: "frameworkAdmin",
    components: {
        FooterComponent,
        HeaderComponent,
        LandingPage,
        BreadcrumbComponent
    },
    setup() {
        sharedVariable.value = window.location.pathname;
      //  this.methodThatForcesUpdate();
    },
    created() {
        this.breadcrumbStyles = {
            width: '95.5%',
        }
        const mediaQuery = window.matchMedia('((min-width:1601px) and (max-width:1870px))');
        if(mediaQuery.matches){
            this.leftBodyStyles ={
                padding:'2rem !important'
            }
            this.headerResponsiveStyles={
                marginTop:'0.5rem !important'
            }
            this.displayBoxResponsive ={
                marginBottom:'0em !important'
            }
            this.viewDashBoardStyles={
                marginTop:'1.5em !important'
            }
        }
        this.$store.commit('clearHistory');
        this.$store.commit('addToHistory', this.$route);
        SharedService.clearData('project.model');
        SharedService.clearData('client.model');
        this.displayUserNameAndRole();
        this.checkRole();
    },
    data() {
        return {
            userName: '',
            role: '',
            navigationClick: 'Home',
            userBasedRole: '',
            renderComponent:true,
            leftBodyStyles:{},
            headerResponsiveStyles:{},
            viewDashBoardStyles:{}
        }
    },
    methods: {
        // methodThatForcesUpdate() {
        //     // ...
        //     this.$forceUpdate();  // Notice we have to use a $ here
        //     // ...
        //   },
        startNowClick() {
            this.$router.push("/searchAssessment");
        },
        frameworkDashboardClick() {
            this.$router.push("/frameworkDashboard");
        },
        startNowFrameworkClick(){
            this.$router.push("/frameworkList");
        },
        displayUserNameAndRole() {
            const username = SharedService.getDataFromBrowser('firstName');
            this.userName = username;
            const roles = SharedService.getDataFromBrowser('role');
            this.role = roles;
        },
        checkRole() {
            if (this.role == "assessor") {
                this.userBasedRole = 'a Assessor'
            } else if (this.role == "frameworkAdmin") {
                this.userBasedRole = 'an Administrator'
            } else if (this.role == "reviewer") {
                this.userBasedRole = 'a Reviewer'
            }
        },
        homeClick() {
            if (this.navigationClick == "Home") {
                this.$router.push("/");
            }
        }
    },
};