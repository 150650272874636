<template>
  <HeaderComponent />
  <FooterComponent />
  <SideNav />
  <ToastComponent :visible="toastState.visible" :message="toastState.message" :type="toastState.type" :imageType="toastState.imageType"></ToastComponent>
  <div class="container-fluid p-0">
    <div class="row login-bg m-0">
      <div class="rightContainer d-flex justify-content-end p-0 m-0" :style="deleteModuleStyles">
        <div class="signIn"></div>
        <div class="mx-auto d-flex flex-column justify-content-start align-items-center searchAssessmentBox" :style="searchAssessmentList">
          <div class="row p-0 d-flex justify-content-start align-items-center mb-3 breadcrumbsResponsive" :style="breadcrumbStyles">
                <BreadcrumbComponent />
          </div>
          <div class="row mx-auto d-flex justify-content-center align-items-center ms-5 me-1 mb-5 searchAssessmentResponsive" v-if="showAssessmentSearchFilter">
            <div class="headingTextBoxOne d-flex align-items-center ms-5">Basic Framework Details</div>
            <div class="whiteBoxOne ms-5" v-if="showFirstSearchBox">
              <!-- <div class="subHeadingText mt-3 mb-3 row g-3 p-0">Search Filter</div> -->
              <form class="row g-3 p-0">
                <div class="d-flex flex-column justify-content-between p-0 ">                   
                    <div class="d-flex align-items-center">
                      <div class="col-4 d-flex flex-column">
                          <label for="frameworkName" class="form-label createAssessmentLabel">Framework Name</label>
                          <input type="text" v-model="FrameworkModel.name" name="frameworkName" class="uploadArtifactFields"/>
                          <div v-if="errors.frameworkName" class="validationMessage">{{ errors.frameworkName }}</div>
                      </div>
                      <div class="col-4 d-flex flex-column">
                          <label for="frameworkType" class="form-label createAssessmentLabel">Framework Type</label>
                          <div class="dropdownSelect dropdownResponsive">
                              <select v-model="FrameworkModel.type" class="dynamicDropdown" :disabled="!FrameworkModel.name">
                              <option disabled selected :value="undefined" class="dropdownOption">Select the type of framework</option>
                              <option v-for="framework in frameworkTypes" :key="framework.id" :value="framework" selected>
                                  {{ framework }}
                              </option>
                              </select>
                              <div v-if="errors.frameworkType" class="validationMessage">{{ errors.frameworkType }}</div>
                          </div>
                      </div>
                      <div class="col-4 d-flex flex-column">
                          <label for="status" class="form-label createAssessmentLabel">Status</label>
                          <input :class="validateFrameworkStatus(FrameworkModel.status)" v-model="status" type="text" autocomplete="off" id="status" name="frameworkStatus" class="uploadArtifactFields" readonly>
                      </div> 
                    </div>
                    <div class="d-flex justify-content-between align-items-end mt-5 formSecondRow">
                      <div class="col-4 d-flex flex-column">
                          <label for="description" class="form-label createAssessmentLabel">Description</label>
                          <textarea v-model="FrameworkModel.description" type="text" id="description" name="description" class="descriptionField" placeholder="A brief description of the Framework" maxlength="100" @keypress="checkTextareaLength"></textarea>
                          <div v-if="errors.frameworkDescription" class="validationMessage">{{ errors.frameworkDescription }}</div>
                      </div>     
                      <div class="col-4 d-flex flex-column">
                        <label for="clientName" class="form-label createAssessmentLabel">Client Name</label>
                        <div class="dropdownSelect dropdownResponsive">
                          <select v-model="FrameworkModel.clientId" id="clientName" class="dynamicDropdown" :value="value" ref= 'input'>
                            <option disabled selected :value="{}" class="dropdownOption">Client Name</option>
                            <option v-for="client in clientList" :key="client.id" :value="client.id" selected>
                              {{ client.clientName }}
                            </option>
                          </select>    
                          <div v-if="clientError" class="validationMessage">{{ clientError }}</div>                    
                        </div>
                      </div>
                      <div class="col-4 d-flex justify-content-end">                    
                        <button type="search" class="btn btn-primary saveButton" v-on:click="createFramework()">
                            Save
                        </button>
                      </div>                   
                    </div>
                </div>
              </form> 
            </div> 
            <div class="whiteBoxOne ms-5" v-if="showSecondSearchBox">
            <form class="row g-3 p-0">
              <div class="d-flex flex-column justify-content-between mt-3 p-0 ">
                <div class="d-flex justify-content-between align-items-center" style="width: 78.5%;">
                  <div class="col-4 d-flex flex-column">
                        <label for="clientName" class="form-label searchAssessmentLabel">Framework Name</label>
                        <div class="searchAssessPlaceHolder">
                          <input v-model="FrameworkModel.name" name="frameworkName" type="text" readonly class="fontSize150"/>
                        </div>
                  </div>
                  <div class="col-4 d-flex flex-column">
                        <label for="frameworkType" class="form-label searchAssessmentLabel">Framework Type</label>
                        <div class="searchAssessPlaceHolder">
                         <input v-model="FrameworkModel.type" name="frameworkType" type="text" readonly class="fontSize150"/>
                        </div>
                  </div>
                  <div class="col-4 d-flex flex-column">
                        <label for="statusInput" class="form-label searchAssessmentLabel">Status</label>
                        <div class="searchAssessPlaceHolder">
                          <input :class="validateFrameworkStatus(FrameworkModel.status)" v-model="status" name="statusInput" id="statusInput" type="text" placeholder="All" readonly class="fontSize150"/>
                        </div>
                  </div>
                  <div class="col-4 d-flex flex-column">
                        <label for="clientName" class="form-label searchAssessmentLabel">Framework Description</label>
                        <textarea v-model="FrameworkModel.description" type="text" id="description" name="description" class="descriptionField mt-2" :title="textarea ? '': FrameworkModel.description" readonly></textarea>
<!--                         <div class="searchAssessPlaceHolder">
                          <input v-model="FrameworkModel.description" name="description" type="text" readonly class="fontSize150"/>
                        </div> -->
                  </div>
                </div>
                <div class="d-flex justify-content-end align-items-center mt-4 editButtonAlignment">
                  <button type="reset" class="btn btn-primary editButton" v-on:click="reFilterClick()">
                    Edit
                  </button>
                </div>
              </div>
            </form>
            </div> 
            <div class="d-flex justify-content-start align-items-center mt-5 headingTextBox" v-if="showFrameworkBox" >
              <div class="d-flex justify-content-between align-items-center">
                <span>Framework Configuration</span>
              </div>
            </div>
            <div class="whiteBox ms-5 mb-5" id="whiteBoxTwo"  v-if="showFrameworkBox">
              <div class="d-flex justify-content-start align-items-start fontSize150">
                <ul class="maincategoryList" style="width: 100%;">
                  <div class="d-flex flex-column">
                    <li class="subCategoryListItems"  v-if="enableAddCategory">
                      <div class="d-flex align-items-center" @click=addCategory(category.length,null,type[0],FrameworkModel._id)>
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="ms-2 addIcon">

                          </div>
                        </div> <!-- Div for plus icon to add category -->
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex align-items-center">
                            <span class="mainCategoryQuestionContent ms-1" id="activeCategory">
                              Add Category
                            </span>
                          </div>   
                          <div class="validationMessage" :style="validationMessageStyle">
                          {{category.catErrorMsg}}
                        </div>                        
                        </div> <!-- Div for Add category Text -->
                      </div>
                      <div class="d-flex flex-column" >
                        <li v-for="(cat,catIndex) in category" :key="cat.catId" style="width:99%;padding-left: 1rem;" ref="categoryHeight">                            
                          <div class="d-flex justify-content-between align-items-top addNewDiv">
                            <div @click="toggleCategory(cat,catIndex,!isCatActive)" :class="{'categoryMaximiseIcon mt-4':!cat.active,'categoryMinimiseIcon mt-4':cat.active}">
                              <div  class="categoryLinkLine" :style="{ height:categoryLineHeight[catIndex] + 'px'}">
                              </div>
                            </div>
                            <div class="col-12 d-flex justify-content-between align-items-top mt-4">                            
                                <div class="col-3 d-flex flex-column ms-3">
                                  <span class="categoryContent">
                                    Category Name
                                  </span>         
                                  <input v-model="cat.name" type="text" class="inputTextName">                           
                                  <div class="validationMessage" :style="validationMessageStyle">
                                        
                                  </div> 
                                </div>
                                <div class="col-5 d-flex flex-column ms-1">
                                  <span class="categoryContent">
                                    Category Description
                                  </span>         
                                  <textarea v-model="cat.description" type="text" id="description" name="description" class="descriptionField" placeholder="A brief description of the Category" maxlength="100"></textarea>                         
                                  <div class="validationMessage" :style="validationMessageStyle">
                                        
                                  </div> 
                                </div>
                                <div class="col-3 d-flex flex-column">
                                  <span class="categoryContent">
                                    Category Weight
                                  </span>         
                                  <input v-model="cat.weight" type="number" class="inputTextName">                           
                                  <div class="validationMessage" :style="validationMessageStyle">
                                        {{ cat.errorMsg }}
                                  </div> 
                                </div>
                                <div class="d-flex flex-column">
                                  <span class="btn deleteIcon p-0" id="categoryDeleteIcon" v-on:click="deleteSelectedFrameworkRecord(cat._id)">
                                   <div v-if="showToolTipDelete" class="toolTip">
                                        Delete
                                    </div>
                                  </span>
                                </div>
                            </div>
                            </div>
                                <!--  Div for Addding Category First row -->
                                <ul class="maincategoryList mt-3 ms-2" style="width:100%;padding-left: 1rem;" v-if="4">
                                    <div class="d-flex flex-column">
                                      <li class="subCategoryListItems" v-if="cat.expanded">
                                        <div class="d-flex align-items-center" @click=addSubCategory(catIndex,category[catIndex].subCategory.length,type[1],category[catIndex]._id)>
                                          <div class="d-flex justify-content-between align-items-center">
                                            <div class="ms-2 addIcon">

                                            </div>
                                          </div> <!-- Div for Subcategory add icon -->
                                          <div class="d-flex justify-content-between align-items-center" >
                                            <div class="d-flex align-items-center">
                                              <span class="mainCategoryQuestionContent ms-1" id="activeCategory">
                                                Add Subcategory
                                              </span>
                                            </div>   
                                            <div class="validationMessage" :style="validationMessageStyle">
                                              {{cat.subCatErrorMsg}}
                                            </div>                        
                                          </div> <!-- Div for adding Sub category Text -->                         
                                        </div>                                                                     
                                        <div class="d-flex flex-column">
                                          <li v-for="(subCat,subCatIndex) in category[catIndex].subCategory" :key="subCat.subCatId" style="width: 99.5%;padding-left: 1rem;" ref="subCategoryHeight">
                                            <div class="d-flex justify-content-between align-items-top addNewDiv">
                                              <div @click="toggleSubCategory(subCat,subCatIndex,!isSubCategoryActive)" :class="{'subCategoryMaximiseIcon mt-4':!subCat.active,'subCategoryMinimiseIcon mt-4':subCat.active}">
                                                <div class="subCatLinkLine" :style="{ height:subCategoryLineHeight[subCatIndex] + 'px'}">
                                                </div>
                                              </div>
                                              <div class="col-12 d-flex justify-content-between align-items-top mt-4">                                
                                                <div class="col-3 d-flex flex-column ms-2" >
                                                  <span class="categoryContent">
                                                    Subcategory Name
                                                  </span>         
                                                  <input v-model="subCat.name" type="text" class="inputTextName">                           
                                                  <div class="validationMessage" :style="validationMessageStyle">
                                                        
                                                  </div> 
                                                </div>
                                                <div class="col-5 d-flex flex-column" style="margin-left: -1.4em;">
                                                  <span class="categoryContent">
                                                    Subcategory Description
                                                  </span>         
                                                  <textarea v-model="subCat.description" type="text" id="description" name="description" class="descriptionField" placeholder="A brief description of the Sub Category" maxlength="100" style="width:87%"></textarea>                         
                                                  <div class="validationMessage" :style="validationMessageStyle">
                                                        
                                                  </div> 
                                                </div>
                                                <div class="col-3 d-flex flex-column ms-2">
                                                  <span class="categoryContent">
                                                    Subcategory Weight
                                                  </span>         
                                                  <input v-model="subCat.weight" type="number" class="inputTextName">                           
                                                  <div class="validationMessage" :style="validationMessageStyle">
                                                        {{ subCat.errorMsg }}
                                                  </div> 
                                                </div>                                                  
                                                <div class="d-flex flex-column ms-1">
                                                      <span class="btn deleteIcon p-0" id="subCategoryDeleteIcon" v-on:click="deleteSelectedFrameworkRecord(subCat._id)">
                                                      <div v-if="showToolTipDelete" class="toolTip">
                                                            Delete
                                                        </div>
                                                      </span>
                                                </div>
                                              </div>
                                            </div>  
                                            
                                  <ul class="maincategoryList mt-3 ms-2" style="width:100%;padding-left: 1rem;">
                                    <div class="d-flex flex-column">
                                      <li class="subCategoryListItems" v-if="subCat.expanded">
                                        <div class="d-flex align-items-center" @click=addQc(catIndex,subCatIndex,type[2],category[catIndex].subCategory[subCatIndex]._id)>
                                          <div class="d-flex justify-content-between align-items-center">
                                            <div class="ms-2 addIcon ">

                                            </div>
                                          </div><!--  Div for add icon -->  
                                          <div class="d-flex justify-content-between align-items-center">
                                            <div class="d-flex align-items-center">
                                              <span class="mainCategoryQuestionContent ms-1" id="activeCategoryg" >
                                                Add Quality Criteria
                                              </span>
                                            </div>   
                                            <div class="validationMessage" :style="validationMessageStyle">
                                              {{ subCat.qcErrorMsg }}
                                            </div>                        
                                          </div> <!-- Div for adding Sub category Text -->                                                     
                                        </div> 
                                        <div class="d-flex flex-column">
                                          <li v-for="(question,index) in category[catIndex].subCategory[subCatIndex].qc" :key="index" style="width:99.4%;padding-left: 1rem;" ref="qcHeight">
                                            <div class="d-flex justify-content-between align-items-center addNewDiv">
                                              <div class="questionListIcon">
                                                <div  class="qcLinkLine" :style="{height:'100px'}">
                                                </div>
                                              </div>
                                              <div class="col-12 mt-4 d-flex justify-content-between align-items-top">
                                                <div class="col-3 d-flex flex-column">
                                                    <span class="categoryContent">
                                                      Quality Criteria                                                    
                                                    </span>         
                                                    <!-- <input type="text" class="inputTextName"> --> 
                                                    <textarea v-model="question.name" type="text" id="qualityCriteriaDescToolTip" name="description" class="descriptionField" placeholder="Quality Criteria" maxlength="100" :title="textareaContent ? '': question.name"></textarea> 
                                                    <!-- <span v-if="question.name" class="toolTipQualityCriteria" id="toolTipQcName">{{ question.name }}</span>  -->                        
                                                    <div class="validationMessage" :style="validationMessageStyle">                        
                                                    </div> 
                                                </div>
                                                <div class="col-3 d-flex flex-column">
                                                    <span class="categoryContent">
                                                      How to Evaluate Quality
                                                    </span>         
                                                    <textarea v-model="question.evaluate" type="text" id="qualityCriteriaDescToolTip" name="guideline" class="descriptionField" placeholder="How to Evaluate Quality" maxlength="250" :title="textareaContent ? '': question.evaluate"></textarea>  
                                                    <!-- <span v-if="question.evaluate" class="toolTipQualityCriteria">{{ question.evaluate }}</span> -->                         
                                                    <div class="validationMessage" :style="validationMessageStyle">                    
                                                    </div> 
                                                </div>
                                                <div class="col-3 d-flex flex-column">
                                                    <span class="categoryContent">
                                                      Scoring Guidelines
                                                    </span>         
                                                    <textarea v-model="question.guideline" type="text" id="qualityCriteriaDescToolTip" name="guideline" class="descriptionField" placeholder="Scoring Guidelines" maxlength="100" :title="textareaContent ? '': question.guideline"></textarea>  
                                                   <!--  <span class="toolTipQualityCriteria">{{ question.guideline }}</span> -->                       
                                                    <div class="validationMessage" :style="validationMessageStyle">
                                                          
                                                    </div> 
                                                </div>
                                                <div class="d-flex flex-column col-2">
                                                    <span  class="categoryContent">
                                                      Question Weight
                                                    </span>         
                                                    <input v-model="question.weight" type="number" class="inputTextName">                           
                                                    <div class="validationMessage" :style="validationMessageStyle">
                                                          {{ question.errorMsg }}
                                                    </div> 
                                                </div>
                                                <div class="d-flex flex-column ms-4">
                                                    <span class="btn deleteIcon p-0" v-on:click="deleteSelectedFrameworkRecord(question._id,type[2],catIndex,subCatIndex)">
                                                    <div v-if="showToolTipDelete" class="toolTip">
                                                          Delete
                                                      </div>
                                                    </span>
                                                </div>
                                               </div>
                                            </div>                                           
                                          </li>

                                        </div>
                                        <div v-if="category[catIndex].subCategory[subCatIndex].qc.length>0" class="linkLineEnd"></div>   
                                      </li>
                                    </div>
                                  </ul>
                                          </li>

                                        </div>
                                        <div v-if="category[catIndex].subCategory.length>0" class="linkLineEnd">  </div>
                                      </li>
                                    </div>
                                  
                                </ul>

                    </li>
              
                  </div>   
                  <div v-if="category.length>0" class="linkLineEnd"></div>
                    </li>
                  </div>
                </ul> 
              </div>
              <div class="d-flex justify-content-end align-items-end mt-5">
                <button type="reset" class="btn btn-primary saveLaterButton me-4" v-on:click="closeFrameworkScreen()">
                      Close
                  </button>
                <button type="reset" class="btn btn-primary saveLaterButton me-4" v-if="category.length>0" v-on:click="saveForLater(true)">
                      Save For Later
                </button>
                <button type="reset" class="btn btn-primary submitButton" v-if="category.length>0" v-on:click="submitFrameworkValidation(false)">
                      Submit
                </button>
              </div>  
            </div>     
          </div>
        </div> 
      </div>
      <pop-module v-if="showDeleteModule" headerMessage="Delete Action" message= "This action will delete the selected Framework record and all the associated record with it. Are you sure you want to delete?" @confirm="deleteFrameworkRecordApi" @cancel="closePopUp"></pop-module>
      <pop-module v-if="showConfirmPopUp" headerMessage="Confirm Action" message= "This action will Submit the selected Framework. Are you sure you want to proceed?" @confirm="submitFramework" @cancel="closePopUp"></pop-module>
  
    </div>
  </div>
</template>
<script src="./createFramework.controller.js"></script>
<style scoped src="./createFramework.style.css"></style>