import axios from '@/axios';
import apiEndpoint from '@/app/services/uri-constants.js';
import { showToast } from "../shared/toast/toast.controller.js";


export default {

    async authentication(User) {
       return axios.post(`${apiEndpoint.authenticate}`, User)
       .catch(function (error) {
        if(error.code=='ERR_BAD_RESPONSE'){
            showToast('Invalid Username or Password.', 'error', 'validationerror');
        }
      //  showToast('Error in login service', 'error', 'validationerror');
        console.log('error---',error);
        throw error;
       });
       
    },
    async refreshToken(accessToken) {

      const response = await axios.get(`${apiEndpoint.refreshToken}` + accessToken)
      return response;

      
   }
};