import FooterComponent from '@/app/shared/footer/FooterComponent.vue';
import HeaderComponent from '@/app/shared/header/HeaderComponent.vue';
import SideNav from '@/app/shared/side-navigation/SideNav.vue';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import { showToast } from '../shared/toast/toast.controller.js';
import ToastComponent from '@/app/shared/toast/ToastComponent.vue';
import { toast } from '../shared/toast/toast.controller.js'
import SharedService from '../services/shared.service.js';
import { sharedVariable } from '../services/sharedData.js'
import Paginate from "vuejs-paginate-next";
import sharedService from '../services/shared.service.js';
import deleteModule from "../shared/custompopup/PopUpModule.vue";
import frameworkService from '../FrameworkDetails/framework.service';
import InfoPopup from "../shared/infopopup/InfoPopup.vue";
/* import {confirmDelete} from "../shared/custompopup/popupModule.controller.js" */
export default {
    name: "searchAssessment",
    components: {
        FooterComponent,
        HeaderComponent,
        BreadcrumbComponent,
        SideNav,
        paginate: Paginate,
        ToastComponent,
        deleteModule,
        InfoPopup
    },
    props: {
        columns: {
            type: Array,
            required: true,
        },
    },
    setup() {
        const loggedInRole = SharedService.getDataFromBrowser("role");
        sharedVariable.value = window.location.pathname;
        return {
            toastState: toast,
            loggedInRole,
        };
    },

    created() {
        this.fetchFrameworkList();
        const mediaQuery = window.matchMedia('((min-width:1025px) and (max-width:1280px))');
        if(mediaQuery.matches){
            this.breadcrumbStyles={
                width: "76.5%",
            }
        }else{
        this.breadcrumbStyles = {
            width: "80.5%",
        };
    }
        // this.showEmptyTableContent = true
        //this.$store.commit("clearHistory");
        this.$store.commit("addToHistory", this.$route);
        this.showBreadcrumbs = true;

    },
    data() {
        return {
            page: 10,
            showDisplayContent: true,
            showDefaultContent: true,
            showFirstSearchBox: true,
            showSecondSearchBox: false,
            clientList: [],
            frameworkList: [],
            assessmentListforAssessor: [],
            userAssignedArtifactList: [],
            clientName: "",
            ClientModel: {},
            showCreateFrameworkButton: true,
            validateClientName: false,
            navigationClick: "Home",
            breadcrumbStyles: {},
            showBreadcrumbs: false,
            showDeleteModule: false,
            deleteModuleStyles: {},
            showEmptyTableContent: false,
            showFrameworkListTableContent: true,
            showToolTipIndex:null,
            activeTooltipType:null,
            showInfoPopUpModule:false,
        };
    },
    methods: {

        showToolTip(index,type){
            this.showToolTipIndex=index;
            this.activeTooltipType=type;
        },
        hideToolTip(index,type)
        {
            console.log(index);
            this.showToolTipIndex=null;
            this.activeTooltipType=type;
        },
        validateFrameworkStatus(status) {
            if (status == "inprogress") {
                return (this.status = "In Progess");
            } else if (status == "submitted") {
                return (this.status = "Submitted");
            } else if (status == "draft") {
                return (this.status = "Draft");
            } else {
                return (this.status = " ");
            }
        },
        createNewFramework() {
            this.$router.push("/createFramework");
        },
        editFramework(framework) {
            sharedService.setData("framework.model", framework);
            if(framework.status==='submitted')
            {
                this.$router.push({ name: "viewFramework", params: {frameworkId: framework._id } });
        
            }
            else{
                this.$router.push({ name: "editFramework", params: { frameworkId: framework._id } });
            }

        },
        deleteFramework(framework) {
            if(framework.isActive=='N')
            {
                this.showDeleteModule = !this.showDeleteModule;
                this.deleteModuleStyles = {
                    opacity: "0.3",
                    id: framework._id
                };
            }else
            {
                this.showInfoPopUpModule = true;
                 this.deleteModuleStyles = {
                    opacity: "0.3",
                };
            }
        },
        closePopUp() {
            this.showDeleteModule = false;
            this.showInfoPopUpModule=false;
            this.deleteModuleStyles = {
                opacity: "1",
            };
        },
        async fetchFrameworkList() {
            frameworkService.fetchFrameworkList().then(response => {
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.frameworkList = response.data.data;
                    if (this.frameworkList.length <= 0) {
                        this.showFrameworkListTableContent = false
                        this.showDefaultContent = true
                        this.showEmptyTableContent=true;
                    } else {
                        this.showFrameworkListTableContent = true
                        this.showDefaultContent = false
                        this.showEmptyTableContent=false;
                    }

                } else {
                    showToast("Failed to fetch Frameworks", "error", 'validationerror');
                }
            })

            .catch(err => {
                // Handle errors
                console.error('error in controller--', err);
            });
        },
        deleteSelectedFramework() {
            frameworkService.deleteFramework(this.deleteModuleStyles.id).then(response => {
                    //if (response.data.header.responseMessage === "SUCCESS")
                    if (response.data.data === "SUCCESS") {
                        showToast("Assessment Deleted Successfully", 'success', 'validationsuccess');
                        this.closePopUp();
                        this.fetchFrameworkList();
                    } else {
                        showToast("Failed to Delete Assessment", 'error', 'validationerror');
                        this.closePopUp();
                    }
                })
                .catch(err => {
                    // Handle errors
                    console.error('error in controller--', err);
                });

        },
        cloneFramework(fraemwork) {
            console.log('clone frmaework----',fraemwork);
            frameworkService.copyFramework(fraemwork._id).then(response => {
                        console.log('response----',response);
                        if(response.status==200){
                            showToast("Framework cloned Successfully", 'success', 'validationsuccess');
                            this.fetchFrameworkList();
                        }else{
                            showToast("Error in clone", 'error', 'validationerror');
                        }
                })
                .catch(err => {
                    // Handle errors
                    console.error('error in controller--', err);
                    showToast("Error in clone", 'error', 'validationerror');
                });

        },
    },
};