const authenticate = 'api/user/authenticate'

//client
const fetchClient = '/api/client/fetchClient?clientId='

//project
const fetchProject = '/api/project/fetchProject?projectId='

//frameworkadmin
const clientList = 'api/client/list'
const projectListByClientId = 'api/project/findByClient?clientId='
const assessmentListByProjectId = 'api/assessment/findAssessmentByProjectId?projectId='
const findAllArtifactByAssessmentId = 'api/artifact/findArtifactByAssessment?assessmentId='

//assessor
const findAllAssessmentByUser = 'api/assessment/findAllAssessmentByUser?userId='
const findArtifactByAssessmentIdAndUserId = 'api/artifact/findArtifactByAssessmentIdAndUserId?assessmentId=' //appending userId in service file

//artifact score
const fetchArtifactScoringById = 'api/score/fetch?currentId='
const saveArtifactScore = 'api/score/save'

//assessment
const createAssessment = 'api/assessment/create'
const fetchAssessment = '/api/assessment/fetchAssessment?assessmentId='
const deleteAssessment = 'api/assessment/delete?currentId='
    //artifact
const artifactListByAssessmentId = 'api/artifact/findArtifactByAssessment?assessmentId='
const updateArtifact = 'api/artifact/update'
const uploadArtifact = 'api/artifact/uploadArtifact'
const deleteArtifact = 'api/artifact/delete?currentId='
const fetchArtifact = 'api/artifact/fetchArtifact?artifactId='
const updateArtifactStatus = 'api/artifact/updateArtifactStatus?artifactId='
    //user
const userListByAssessmentId = 'api/userAccess/findUserByAssessmentId?assessmentId='
const assignUserToArifact = 'api/userAccess/assignUser'
const deleteAssignedUser = 'api/userAccess/delete?artifactId='
    //dashboard
const fetchArtifactScoreList = 'api/score/fetchArtifactScore?artifactId='

//framework
const createFramework = 'api/framework/create'
const createFrameworkRecord = 'api/framework/createRecord'
const saveAllFrameworkDetails = 'api/framework/saveAll'
const fetchFrameworkList = 'api/framework/fetchFrameworkList'
const configureFramework = 'api/framework/search?currentId='
const updateFrameworkStatus='api/framework/updateFrameworkStatus?frameworkId='
const deleteFramework = 'api/framework/deleteFramework?frameworkId='
const copyFramework = 'api/framework/copyFramework?frameworkId='

const deleteFrameworkRecord = 'api/framework/deleteFrameworkRecord?id='
const viewFramework ='api/framework/search?currentId='

//user
const fetchUser = 'api/user/list'
const refreshToken= 'api/user/refreshToken?refreshToken='

export default {
    authenticate: authenticate,
    refreshToken: refreshToken,

    //client
    fetchClient: fetchClient,

    //project
    fetchProject: fetchProject,
    //frameworkAdmin
    clientList: clientList,
    projectListByClientId: projectListByClientId,
    assessmentListByProjectId: assessmentListByProjectId,
    findAllArtifactByAssessmentId: findAllArtifactByAssessmentId,
    //assessor
    findAllAssessmentByUser: findAllAssessmentByUser,
    findArtifactByAssessmentIdAndUserId: findArtifactByAssessmentIdAndUserId,
    //score
    fetchArtifactScoringById: fetchArtifactScoringById,
    saveArtifactScore: saveArtifactScore,

    //assessment
    createAssessment: createAssessment,
    fetchAssessment: fetchAssessment,
    deleteAssessment: deleteAssessment,
    //artifact
    artifactListByAssessmentId: artifactListByAssessmentId,
    updateArtifact: updateArtifact,
    uploadArtifact: uploadArtifact,
    deleteArtifact: deleteArtifact,
    fetchArtifact: fetchArtifact,
    updateArtifactStatus: updateArtifactStatus,

    //user
    userListByAssessmentId: userListByAssessmentId,
    fetchUser: fetchUser,
    assignUserToArifact: assignUserToArifact,
    deleteAssignedUser: deleteAssignedUser,

    //dashboard
    fetchArtifactScoreList: fetchArtifactScoreList,

    //framework
    createFramework: createFramework,
    createFrameworkRecord: createFrameworkRecord,
    configureFramework: configureFramework,
    saveAllFrameworkDetails: saveAllFrameworkDetails,
    fetchFrameworkList: fetchFrameworkList,
    updateFrameworkStatus:updateFrameworkStatus,
    deleteFramework: deleteFramework,
    copyFramework: copyFramework,
    deleteFrameworkRecord: deleteFrameworkRecord,
    viewFramework:viewFramework
}