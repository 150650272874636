import SharedService from '../../services/shared.service.js';
export default {

  setup() {
    const loggedInRole = SharedService.getDataFromBrowser("role");
    return {
        loggedInRole,
    };
},
  methods: {   
    clickDashboard() {
      this.$store.commit("removeFromHistory", this.routePath);
      this.$router.push("/frameworkDashboard");
    },

    clickAssesment() {
      this.$store.commit("removeFromHistory", this.routePath);
      this.$router.push("/searchAssessment");
    },

    clickFramework(){
      this.$store.commit("removeFromHistory", this.routePath);
      this.$router.push("/frameworkList")
    }
  }
}