import { createRouter, createWebHistory } from "vue-router";
// import AboutView from '../app/about/AboutView.vue'
import LandingPage from "../app/landingpage/LandingPage.vue";
import ResetPassword from "../app/login/reset-password/ResetPassword.vue";
import CreateAssessment from "../app/AssessementDetails/CreateAssessment.vue";
import SearchAssessment from "../app/AssessementDetails/SearchAssessment.vue";
import ArtifactList from "../app/ArtifactDetails/ArtifactList.vue"
import UserList from "../app/UserDetails/UserList.vue";
import FrameworkDashboard from "../app/Dashboard/FrameworkDashboard.vue";
/* import chartsWorld from "../app/charts/chartsWorld.vue"; */
import AssessmentScoring from "../app/AssessementDetails/AssessmentScoring.vue";
import FrameworkList from "../app/FrameworkDetails/FrameworkList.vue";
import CreateFramework from "../app/FrameworkDetails/CreateFramework.vue";
import ViewFramework from "../app/FrameworkDetails/ViewFramework.vue";


const routes = [
    // {
    //     path: '/',
    //     name: 'about',
    //     component: AboutView
    // },
    {
        path: "/",
        name: "login",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../app/login/LoginComponent.vue"),
    },
    {
        path: "/landingPage",
        name: "LandingPage",
        component: LandingPage,
        meta: { breadcrumb: 'Home' }
    },
    {
        path: "/resetPassword",
        name: "resetPassword",
        component: ResetPassword,
    },
    {
        path: "/createAssessment",
        name: "createAssessment",
        component: CreateAssessment,
        meta: { breadcrumb: 'Create Assessment' }
    },
    {
        path: "/editAssessment/:assessmentId",
        name: "editAssessment",
        component: CreateAssessment,
        meta: { breadcrumb: 'Edit Assessment' }
    },
    {
        path: "/viewAssessment/:assessmentId",
        name: "viewAssessment",
        component: CreateAssessment,
        meta: { breadcrumb: 'View Assessment' }
    },
    {
        path: "/searchAssessment",
        name: "searchAssessment",
        component: SearchAssessment,
        props: true,
        meta: { breadcrumb: 'Search Assessments' }
    },
    {
        path: "/artifactList/:assessmentId",
        name: "artifactList",
        component: ArtifactList,
        meta: { breadcrumb: 'Artifact List' }
    },
    {
        path: "/userList/:assessmentId",
        name: "userList",
        component: UserList,
        meta: { breadcrumb: 'User List' }
    },
    {
        path: "/frameworkDashboard",
        name: "frameworkDashboard",
        component: FrameworkDashboard,
        meta: { breadcrumb: 'FrameworkDashboard' }
    },
    {
        path: "/frameworkDashboard/:assessmentId",
        name: "assessmentDashboard",
        component: FrameworkDashboard,
        meta: { breadcrumb: 'AssessmentDashboard' }
    },
    {
        path: "/artifactFrameworkDashboard/:artifactId",
        name: "artifactFrameworkDashboard",
        component: FrameworkDashboard,
        meta: { breadcrumb: 'ArtifactDashboard' }
    },
    {
        path: "/assessmentScoring/:assessmentId/:assessmentName",
        name: "assessmentScoring",
        component: AssessmentScoring,
        meta: { breadcrumb: 'Artifact Scoring' }
    },
    {
        path: "/frameworkList",
        name: "frameworkList",
        component: FrameworkList,
        props: true,
        meta: { breadcrumb: 'Framework List' }
    },
    {
        path: "/createFramework",
        name: "createFramework",
        component: CreateFramework,
        props: true,
        meta: { breadcrumb: 'Create Framework' }
    },

    {
        path: "/editFramework/:frameworkId",
        name: "editFramework",
        component: CreateFramework,
        props: true,
        meta: { breadcrumb: 'Edit Framework' }
    },

    {
        path: "/viewFramework/:frameworkId",
        name: "viewFramework",
        component: ViewFramework,
        meta: { breadcrumb: 'View Framework' }
    },
    // {
    //     path: "/assessmentScoring/:assessmentId",
    //     name: "assessmentScoring",
    //     component: AssessmentScoring,
    //     meta: { breadcrumb: 'Artifact Scoring' }
    // },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;