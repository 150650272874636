import axios from '../shared/interceptor.js';
import apiEndpoint from '@/app/services/uri-constants.js';

export default {

    async fetchArtifactScoreList(id) {
        const response = await axios.get(`${apiEndpoint.fetchArtifactScoreList}` + id)
        return response;

    },


}