import FooterComponent from '@/app/shared/footer/FooterComponent.vue';
import HeaderComponent from '@/app/shared/header/HeaderComponent.vue';
import SearchAssessment from '@/app/AssessementDetails/SearchAssessment.vue';
import SideNav from '@/app/shared/side-navigation/SideNav.vue';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import AssessmentScoring from '@/app/AssessementDetails/AssessmentScoring.vue';
//import artifactScoreModel from '@/app/model/artifactScore.model.js';
import assessmentService from '@/app/AssessementDetails/assessment.service.js';
import artifactService from '../ArtifactDetails/artifact.service.js';
import { showToast } from '../shared/toast/toast.controller.js';
import ToastComponent from '@/app/shared/toast/ToastComponent.vue';
import { toast } from '../shared/toast/toast.controller.js'
import SharedService from '../services/shared.service.js';
import { ref, toRaw, reactive } from 'vue'
import PopUpModule from "../shared/custompopup/PopUpModule.vue";
import InfoPopup from "../shared/infopopup/InfoPopup.vue";

export default {

    name: 'assessmentScoring',
    components: { FooterComponent, HeaderComponent, SearchAssessment, SideNav, AssessmentScoring, ToastComponent, BreadcrumbComponent, PopUpModule, InfoPopup },
    props: {},
    setup() {
        const showManualScoreError = ref({})
        const loggedInRole = SharedService.getDataFromBrowser('role');
        const hasError = (id) => {
            return !!showManualScoreError.value[`${id}`];
        };
        return {
            toastState: toast,
            loggedInRole,
            hasError,
            showManualScoreError
        }
    },
    created() {
        this.ArtifactModel = SharedService.getData("artifact.model");
        if (this.ArtifactModel) {
            this.showDetails = true;
            this.fetchArtifactScoring(this.ArtifactModel);
        } else {
            this.showDetails = false;
            this.ArtifactModel = {};
        }
        if (this.loggedInRole == 'frameworkAdmin') {
            this.viewScore = true
        }
        this.routePath = '/assessmentScoring';
        this.$store.commit('addToHistory', this.$route);
        this.breadcrumbStyles = {
            width: '84%'
        }
        this.assessmentId = this.$route.params.assessmentId;
        if (this.loggedInRole == 'assessor' || this.loggedInRole == 'reviewer') {
            this.findArtifactByAssessmentIdAndUserId(this.assessmentId);
            if (this.loggedInRole == 'reviewer') {
                this.viewScore = true
            }
        }
        if (this.loggedInRole == 'frameworkAdmin') {
            this.loadArtifactByAssessmentId(this.assessmentId);
        }
    },
    beforeUnmount() {
        this.$store.commit("removeFromHistory", this.routePath);
    },
    data() {
        return {
            artifactScoreModel: [],
            isActive: false,
            isSubCategoryActive: false,
            artifactType: '',
            ArtifactModel: {},
            selectedArtifactType: '',
            artifactName: '',
            userAssignedArtifactList: [],
            artifactList: [],
            assessmentName: this.$route.params.assessmentName,
            artifactTypeError: '',
            artifactNameError: '',
            enableQualityCriteria: false,
            validateArtifactType: false,
            validateArtifactName: false,
            inputTextError: '',
            inputText: '',
            maxlength: 100,
            submitArtifactToast: false,
            assessmentId: '',
            indexValue: '',
            throwSubmitError: false,
            throwSubmitErrorMsg: '',
            throwSaveLaterError: false,
            throwSaveLaterErrorMsg: '',
            navigationClick: 'Home',
            frameworkId: '',
            validationMessageStyle: {},
            validationMessageStyleForSubmit: {},
            categoryLineHeight: [],
            subCategoryLineHeight: [],
            qcLineHeight: [],
            catIndex: '',
            questionListLinkEnd: {},
            breadcrumbStyles: {},
            artifactTypes: [],
            viewScore: false,
            routePath: '',
            showPopUpModule: false,
            showPopUpModuleReject: false,
            showApprovePopUpModule: false,
            showAlreadySubPopUpModule: false,
            showAlreadyRejPopUpModule: false,
            showAlreadyAppPopUpModule: false,
            popUpModuleStyles: {},
            serverRequestOn: false,
            showDetails: false,
            validToSaveScore: true,
        }

    },
    methods: {
        refilter() {
            this.showDetails = false;
            this.ArtifactModel = {};
            this.enableQualityCriteria = false;
        },

        artifactStatus(status) {
            if (status == "inprogress") {
                return (this.status = "In Progess");
            } 
            else if (status == "draft") {
                return (this.status = "Draft");
            } 
            else if (status == "submitted") {
                return (this.status = "Submitted");
            }
            else if (status == "approved") {
                return (this.status = "Approved");
            }
            else if (status == "rejected") {
                return (this.status = "Rejected");
            } else {
                return (this.status = status);
            }
        },
        toggleCategory(item, index, flag) {
            if (item.expanded === undefined) {
                item.expanded = true;
                item.active = true;
                this.isActive = false;
            } else if (item.expanded === true) {
                item.expanded = false;
                item.active = false;
                this.isActive = flag;
            } else if (item.expanded === false) {
                item.expanded = true;
                item.active = true;
                this.isActive = false;
            }
            this.catIndex = index
            setTimeout(() => this.dynamicCategoryListHeight(index), 100);
            setTimeout(() => {
                this.$refs.subCategoryHeight.forEach((_, index) => {
                    this.dynamicSubCatListHeight(index)
                }, 50)
            })
        },
        togglesSubCategory(subCategoryitem, index, flag) {
            if (subCategoryitem.expanded === undefined) {
                subCategoryitem.expanded = true;
                subCategoryitem.active = true;
                this.isSubCategoryActive = false;
            } else if (subCategoryitem.expanded === true) {
                subCategoryitem.expanded = false;
                subCategoryitem.active = false;
                this.isSubCategoryActive = flag;
            } else if (subCategoryitem.expanded === false) {
                subCategoryitem.expanded = true;
                subCategoryitem.active = true;
                this.isSubCategoryActive = false;
            }
            setTimeout(() => this.dynamicSubCatListHeight(index), 100);
            setTimeout(() => {
                this.$refs.qcHeight.forEach((_, index) => {
                    this.dynamicQcListHeight(index)
                }, 50)
            })
        },
        dynamicSubCatListHeight(index) {
            const divSubCategoryElement = this.$refs.subCategoryHeight[index]
            if (divSubCategoryElement) {
                this.subCategoryLineHeight[index] = divSubCategoryElement.clientHeight + 30
            }
            console.log(this.subCategoryLineHeight[index])
            this.dynamicCategoryListHeight(this.catIndex)
        },
        dynamicCategoryListHeight(index) {
            const divCategoryElement = this.$refs.categoryHeight[index]
            if (divCategoryElement) {
                this.categoryLineHeight[index] = divCategoryElement.clientHeight + 30
                console.log(this.categoryLineHeight[index])
            }

        },
        dynamicQcListHeight(index) {
            const divQcElement = this.$refs.qcHeight[index]
            if (divQcElement) {
                this.qcLineHeight[index] = divQcElement.clientHeight + 30
                this.questionListLinkEnd = {
                    margin: '10px 0px 0px 15px'
                }
                console.log(this.qcLineHeight[index])
            }

        },
        homeClick() {
            if (this.navigationClick == "Home") {
                this.$router.push("/landingPage");
            }
        },
        validateSelection(artifact) {
            if ((this.validateArtifactType) && (this.validateArtifactName)) {
                this.artifactTypeError = "";
                this.artifactNameError = "";
                this.fetchArtifactScoring(artifact);
                event.preventDefault();
            } else if ((this.validateArtifactType == true) && (this.validateArtifactName == !true)) {
                this.artifactNameError = "Please select Artifact Name";
                this.artifactTypeError = "";
                event.preventDefault();

            } else if ((this.validateArtifactType == !true) && (this.validateArtifactName == true)) {
                this.artifactTypeError = "Please select Artifact Type";
                event.preventDefault();
            } else {
                this.artifactTypeError = "Please select Artifact Type";
                event.preventDefault();
            }
        },
        searchClick() {
            this.enableQualityCriteria = true;
            event.preventDefault();
        },
        saveForLater() {
            this.saveScore();
            this.validToSaveScore = true;
        },

        loadArtifactNameByType(event) {
            this.validateArtifactType = true;
            this.artifactListByType = [];
            if (this.loggedInRole == 'assessor' || this.loggedInRole == 'reviewer') {
                for (let artifact of toRaw(this.userAssignedArtifactList)) {
                    if (event.target.value === artifact.artifactType) {
                        this.artifactListByType.push(reactive(artifact));
                    }
                }
            } else {
                for (let artifact of toRaw(this.artifactList)) {
                    if (event.target.value === artifact.artifactType) {
                        this.artifactListByType.push(reactive(artifact));
                    }
                }
            }
            this.artifactTypeError = "";
            this.artifactNameError = "";
        },
        setArtifactFlags() {
            this.validateArtifactName = true;
            this.artifactNameError = "";
        },

        enableSearchClick(artifact) {
            this.validateSelection(artifact);
            if (this.loggedInRole == 'assessor') {
                if (artifact.artifactStatus == 'submitted') {
                    this.confirmAlreadySubApproval();
                } else if (artifact.artifactStatus == 'rejected') {
                    this.confirmAlreadyRejApproval();
                } else if (artifact.artifactStatus == 'approved') {
                    this.confirmAlreadyApproved();
                }
            }
            if (this.loggedInRole == 'reviewer') {
                if (artifact.artifactStatus == 'approved') {
                    this.confirmAlreadyApproved();
                }
            }
        },

        async fetchArtifactScoring(artifact) {

            this.ArtifactModel = artifact;
            SharedService.setData("artifact.model", artifact);
            this.frameworkId = artifact.frameworkId;
            this.assessmentId = artifact.assessmentId;
            if (artifact.artifactStatus == 'submitted' || artifact.artifactStatus == 'approved') {
                this.viewScore = true;
            }
            assessmentService.fetchArtifactScoring(artifact.frameworkId, artifact.id).then(response => {
                    if (response.status === 200) {
                        this.artifactScoreModel = response.data
                        console.log("Assessement", this.artifactScoreModel.data)
                        this.searchClick();
                        setTimeout(() => {
                            this.$refs.categoryHeight.forEach((_, index) => {
                                this.dynamicCategoryListHeight(index)
                            }, 100)
                        })
                    } else {
                        showToast('Failed to fetch Scores for selected Artifact', 'error', 'validationerror')
                    }
                })
                .catch(err => {
                    // Handle errors
                    console.error('error in controller--', err);
                    showToast('Failed to fetch Scores for selected Artifact', 'error', 'validationerror')
                });
        },
        checkLength() {
            if (this.inputText.length > this.maxlength) {
                this.inputTextError = "Text exceeds the maximum limit allowed"
            }
        },

        checkScore(questionList) {
            if (questionList.score > 100) {
                questionList.parentId = true
                this.validationMessageStyle = {
                    marginTop: '3.2%'
                }
                this.manualScoreError = "Enter values between 1 to 100"
            } else if ((questionList.score > 0) && (questionList.score <= 100)) {
                questionList.parentId = false
            } else if (questionList.score <= 0) {
                questionList.parentId = true
                this.validationMessageStyle = {
                    marginTop: '3.2%'
                }
                this.manualScoreError = "Enter values between 1 to 100"
            }
        },
        closeScore() {
            this.$router.push("/searchAssessment");
        },

        artifactDashboard(id) {
            if (this.ArtifactModel.artifactStatus != 'draft') {
                this.$router.push({ name: "artifactFrameworkDashboard", params: { artifactId: id } });
            } else {
                showToast('No score saved', 'error', 'validationerror');
            }
        },
        scrollToTop() {
            this.validationMessageStyle = {
                marginTop: '3.2%'
            }
            window.scrollTo(0, 0);
        },
        confirmSubmitApproval() {
            this.showPopUpModule = true;
            this.popUpModuleStyles = {
                opacity: "0.3",
            };
        },
        confirmRejectApproval() {
            this.showPopUpModuleReject = true;
            this.popUpModuleStyles = {
                opacity: "0.3",
            };
        },
        confirmApproval() {
            this.showApprovePopUpModule = true;
            this.popUpModuleStyles = {
                opacity: "0.3",
            };
        },
        confirmAlreadySubApproval() {
            this.showAlreadySubPopUpModule = true;
            this.popUpModuleStyles = {
                opacity: "0.3",
            };
        },
        confirmAlreadyRejApproval() {
            this.showAlreadyRejPopUpModule = true;
            this.popUpModuleStyles = {
                opacity: "0.3",
            };
        },
        confirmAlreadyApproved() {
            this.showAlreadyAppPopUpModule = true;
            this.popUpModuleStyles = {
                opacity: "0.3",
            };
        },
        closePopUp() {
            this.showPopUpModule = false;
            this.showApprovePopUpModule = false;
            this.showPopUpModuleReject = false;
            this.showAlreadySubPopUpModule = false;
            this.showAlreadyRejPopUpModule = false;
            this.showAlreadyAppPopUpModule = false;
            this.popUpModuleStyles = {
                opacity: "1",
            };
        },
        async submitApproval() {
            if (this.ArtifactModel.artifactStatus == 'submitted') {
                this.confirmAlreadySubApproval();
                this.throwSubmitError = false
                showToast('Already submitted for Approval', 'success', 'validationsuccess')

            } else {
                this.serverRequestOn = true;
                this.validateSubmitApproval();
                if (this.validToSaveScore) {
                    this.saveScoreAPI();
                    this.confirmSubmitApproval();
                    this.throwSubmitErrorMsg = " ";
                    this.throwSaveLaterErrorMsg = " ";
                } else {
                    this.throwSaveLaterErrorMsg = " ";
                    this.scrollToTop();
                    this.serverRequestOn = false;
                }
                this.validToSaveScore = true;
            }
        },
        rejectApproval() {
            if (this.ArtifactModel.artifactStatus == 'rejected') {
                this.throwSubmitError = false
                showToast('Already submitted for reject', 'success', 'validationsuccess')

            } else {
                this.saveScoreAPI();
                this.confirmRejectApproval();
            }
        },
        artifactApproval() {
            if (this.ArtifactModel.artifactStatus == 'approved') {
                this.throwSubmitError = false
                showToast('Already submitted for Approval', 'success', 'validationsuccess')

            } else {
                this.saveScoreAPI();
                this.confirmApproval();
            }
        },
        updatedScore() {
            //  let artifactScoreData = {};
            const qcScoreList = [];
            this.artifactScoreModel.data.category.forEach(category => {
                category.subCategory.forEach(subCategory => {
                    subCategory.qc.forEach(qc => {
                        // if (qc.score != null && (qc.existingScoreId === null)) {
                        if (qc.score > 0 && qc.score <= 100) {
                            qcScoreList.push({
                                _id: qc.existingScoreId,
                                // assessmentId: this.assessmentId,
                                artifactId: this.ArtifactModel.id,
                                // frameworkId: category.parentId,
                                // categoryId: category._id,
                                // subCategoryId: subCategory._id,
                                // qcId: qc._id,
                                score: qc.score,
                                assessorComment: qc.assessorComment,
                                reviewerComment: qc.reviewerComment
                            });
                        }
                    });
                });
            });
            return qcScoreList;
        },
        async submitArtifact() {
            this.closePopUp();
            const response = await artifactService.updateArtifactStatus(this.ArtifactModel.id, 'submitted')
            if (response.data.header.responseMessage === "SUCCESS") {
                console.log(response.data);
                this.ArtifactModel = response.data.data;
                SharedService.setData("artifact.model", this.ArtifactModel);
                // this.$router.push("/searchAssessment");
                this.serverRequestOn = false;
                showToast('Successfully submitted for Approval', 'success', 'validationsuccess')
            } else {
                showToast('Failed to Submit the Artifact for Approval', 'error', 'validationerror')
                console.log("error");
                this.serverRequestOn = false;
            }
        },
        async rejectArtifact() {
            this.closePopUp();
            const response = await artifactService.updateArtifactStatus(this.ArtifactModel.id, 'rejected')
            if (response.data.header.responseMessage === "SUCCESS") {
                console.log(response.data);
                this.ArtifactModel = response.data.data;
                SharedService.setData("artifact.model", this.ArtifactModel);
                // this.$router.push("/searchAssessment");
                this.serverRequestOn = false;
                showToast('Successfully Rejected for Approval', 'success', 'validationsuccess')
            } else {
                showToast('Failed to Reject the Artifact', 'error', 'validationerror')
                console.log("error");
                this.serverRequestOn = false;
            }
        },

        async approveArtifact() {
            this.closePopUp();
            const response = await artifactService.updateArtifactStatus(this.ArtifactModel.id, 'approved')
            if (response.data.header.responseMessage === "SUCCESS") {
                console.log(response.data);
                this.ArtifactModel = response.data.data;
                SharedService.setData("artifact.model", this.ArtifactModel);
                // this.$router.push("/searchAssessment");
                this.serverRequestOn = false;
                showToast('Successfully Approved', 'success', 'validationsuccess')
            } else {
                showToast('Failed to Approve the Artifact', 'error', 'validationerror')
                console.log("error");
                this.serverRequestOn = false;
            }
        },

        validateScores() {
            this.artifactScoreModel.data.category.forEach(category => {
                category.subCategory.forEach(subCategory => {
                    subCategory.qc.forEach(qc => {
                        if ((qc.score > 100) || (qc.score < 0)) {
                            this.throwSaveLaterError = true
                            this.validationMessageStyleForSubmit = {
                                margin: '0% 0% 0% 1% !important',
                                fontSize: '15px !important'
                            }

                            this.validToSaveScore = false;
                            this.throwSaveLaterErrorMsg = "Please enter the values between 1 to 100"

                        }
                    })
                })
            })

        },
        validateSubmitApproval() {
            this.artifactScoreModel.data.category.forEach(category => {
                category.subCategory.forEach(subCategory => {
                    subCategory.qc.forEach(qc => {
                        if ((qc.score > 100) || (qc.score < 0) || (qc.score == '')) {
                            this.throwSubmitError = true
                            this.validationMessageStyleForSubmit = {
                                margin: '0% 0% 0% 1% !important',
                                fontSize: '15px !important'
                            }
                            this.validToSaveScore = false;
                            this.throwSubmitErrorMsg = "Please enter Score for all Quality Criterion"
                        }
                    })
                })
            })

        },
        saveScore() {
            this.serverRequestOn = true;
            this.validateScores();
            if (this.validToSaveScore) {
                this.saveScoreAPI();
                this.throwSaveLaterErrorMsg = " ";
                this.throwSubmitErrorMsg = " ";
                showToast('Score Saved successfully', 'success', 'validationsuccess')
            } else {
                this.throwSubmitErrorMsg = " ";
                this.scrollToTop();
                this.serverRequestOn = false;
            }
        },
        async saveScoreAPI() {
            const qcScoreList=this.updatedScore();
            const reqObject={
                saveScoreList  :qcScoreList,
                artifactStatus:this.ArtifactModel.artifactStatus
            }


            const response = await assessmentService.saveArtifactScore(reqObject);
            if (response.status === 200) {
                console.log(response.data);
                /* this.throwSubmitError = false */
                // if (!this.submitArtifactToast) {
                this.serverRequestOn = false;
                this.scrollToTop();
                // showToast("Score saved successfully", 'success', 'validationsuccess')
                //}
                const artifactResponse = await artifactService.fetchArtifactById(this.ArtifactModel.id);
                if (artifactResponse.data.header.responseMessage === "SUCCESS") {
                    this.ArtifactModel = artifactResponse.data.data;
                    SharedService.setData("artifact.model", this.ArtifactModel);
                } else {
                    showToast("Failed to fetch Arifact details", 'error', 'validationerror')
                }
                //  this.submitArtifactToast = false;
               // this.fetchArtifactScoring(this.ArtifactModel);

            } else {
                this.serverRequestOn = false;
                console.log("error");
                showToast("Failed to save score", 'error', 'validationerror')
            }

        },
        async findArtifactByAssessmentIdAndUserId(assessmentId) {
            const userId = SharedService.getDataFromBrowser("userId");
            const response = await assessmentService.findArtifactByAssessmentIdAndUserId(assessmentId, userId)
            console.log("ID:", assessmentId)
            if (response.data.header.responseMessage === "SUCCESS") {
                this.userAssignedArtifactList = response.data.data
                this.artifactTypes = [...new Set(this.userAssignedArtifactList.map(artifact => artifact.artifactType))]
                console.log('artifactTypes', this.artifactTypes)
            } else {
                showToast('Failed to fetch Projects', 'error', 'validationerror')
            }
        },
        async loadArtifactByAssessmentId(assessmentId) {
            const response = await artifactService.findArtifactListByAssessmentId(
                assessmentId
            );
            console.log("response", response);
            if (response.data.header.responseMessage === "SUCCESS") {
                this.artifactList = response.data.data;
                this.artifactTypes = [...new Set(this.artifactList.map(artifact => artifact.artifactType))]
                    // sharedService.setData("assessment.model", assessment);
            } else {
                showToast("Failed to fetch Artifacts for the selected Assessment", "error", 'validationerror');
            }
        },
    },
}