import FooterComponent from '@/app/shared/footer/FooterComponent.vue';
import HeaderComponent from '@/app/shared/header/HeaderComponent.vue';
import UserList from '@/app/ArtifactDetails/ArtifactList.vue';
import SideNav from '@/app/shared/side-navigation/SideNav.vue';
import userListData from '../../assets/data/listUser.json';
import { SweetModal, SweetModalTab } from 'sweet-modal-vue-3';
import { showToast } from '../shared/toast/toast.controller.js';
import ToastComponent from '@/app/shared/toast/ToastComponent.vue';
import userService from '@/app/UserDetails/user.service.js';
import assessmentService from '@/app/AssessementDetails/assessment.service.js';
import artifactService from '@/app/ArtifactDetails/artifact.service.js';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import { toRaw, reactive } from 'vue';
import sharedService from "../services/shared.service.js";
import deleteModule from "../shared/custompopup/PopUpModule.vue";
export default {
    name: "userList",
    components: {
        FooterComponent,
        HeaderComponent,
        UserList,
        SideNav,
        SweetModal,
        SweetModalTab,
        BreadcrumbComponent,
        ToastComponent,
        deleteModule
    },
    props: {
        columns: {
            type: Array,
            required: true,
        },
    },
    created() {
        // this.$store.commit('addToHistory', this.$route.fullPath);
        this.breadcrumbStyles = {
            width: '87%'
        }
        this.assessmentId = this.$route.params.assessmentId;
        this.AssessmentModel = sharedService.getData('assessment.model');
        if (!this.AssessmentModel) {
            this.fetchAssessment(this.assessmentId);
        }
        this.assessmentUserList(this.assessmentId);
        this.fetchArtifactList(this.assessmentId);
        this.fetchAllUser();
    },
    data() {
        return {
            userList: {},
            users: userListData,
            page: 10,
            dialog: null,
            assessmentId: '',
            artifactListByType: [],
            artifactTypes: [],
            filteredArtifactList: [],
            UserModel: {},
            fetchUserList: {},
            ArtifactModel: {},
            AssessmentModel: {},
            breadcrumbStyles: {},
            showDeleteModule: false,
            showDisplayContent: false,
            errors: {},
            showToolTipIndex:null,
            activeTooltipType:null,
            showDisplayMessage:false,
            tableBodyStyles:{}
        };
    },
    methods: {

        showToolTip(index,type){
            this.showToolTipIndex=index;
            this.activeTooltipType=type;
        },
        hideToolTip(index,type)
        {
            console.log(index);
            this.showToolTipIndex=null;
            this.activeTooltipType=type;
        },

        assessmentList() {
            if (this.assessmentId) {
                this.$router.push({ name: "editAssessment", params: { assessmentId: this.assessmentId, assessmentName: this.assessmentName } });
            } else {
                showToast("Assessment is not created", 'error', 'validationerror');
            }
        },
        artifactList() {
            if (this.assessmentId) {
                this.$router.push({ name: "artifactList", params: { assessmentId: this.assessmentId } });
            } else {
                showToast("Assessment is not created", 'error', 'validationerror');
            }
        },
        closeUserPopUp() {
            this.$refs.modal.close();
            this.assessmentUserList(this.assessmentId);
        },
        artifactTypeValidation() {
            this.artifactListByType = [];
            if (this.UserModel.accessType == undefined) {
                this.errors.artifactType = 'Please select a valid Artifact Type';
            } else {
                this.errors.artifactType = '';
                event.preventDefault();
                console.log('type', this.ArtifactModel);
                for (let artifact of toRaw(this.filteredArtifactList)) {
                    if (this.UserModel.accessType === artifact.artifactType) {
                        this.artifactListByType.push(reactive(artifact));
                    }
                }
            }
        },
        artifactNameValidation() {
            this.UserModel.accessTypeId = this.ArtifactModel.id;
            this.UserModel.frameworkId=this.ArtifactModel.frameworkId;
            if (this.UserModel.accessTypeId == undefined) {
                this.errors.artifactName = "Please select a valid Artifact";
            } else {
                this.errors.artifactName = '';
                event.preventDefault();
            }
        },
        assignUser() {
            this.UserModel = {};
            this.ArtifactModel = {};
            this.$refs.modal.open();
        },
        userValidation() {
            if (this.UserModel.userId == undefined) {
                this.errors.email = "Please select a valid Email";
            } else {
                this.errors.email = "";
                event.preventDefault();
            }
        },
        userRoleValidation() {
            this.filteredArtifactList = [];
            if (this.UserModel.role == undefined) {
                this.errors.role = "Please select a valid Role";
            } else {
                this.errors.role = "";
                //build unique artifact list 
                if(this.UserModel.role == 'reviewer'){
                console.log('type', this.ArtifactModel);
                let reviewerUser = this.userList.map((user)=>  {
                    if(user.role == 'reviewer') return user.artifactId;
                })

                let artifactUser = this.userList.map((user)=>  {
                    if(user.role == 'assessor' && this.UserModel.userId==user.userId) return user.artifactId;
                })

                for (let artifact of toRaw(this.assessmentArtifactList)) {
                   let index= reviewerUser.indexOf(artifact.id);
                   let artifactIndex=artifactUser.indexOf(artifact.id);
                   if(index<0 && artifactIndex<0){
                    this.filteredArtifactList.push(artifact)
                   }
                }
            } 
            else if(this.UserModel.role == 'assessor'){
                console.log('type', this.ArtifactModel);
                let asessorUser = this.userList.map((user)=>  {
                    if(user.role =='assessor') return user.artifactId;
                })
                let artifactUser = this.userList.map((user)=>  {
                    if(user.role == 'reviewer' && this.UserModel.userId==user.userId) return user.artifactId;
                })

                for (let artifact of toRaw(this.assessmentArtifactList)) {
                    let index= asessorUser.indexOf(artifact.id);
                    let artifactIndex=artifactUser.indexOf(artifact.id);
                    if(index<0 && artifactIndex<0){
                     this.filteredArtifactList.push(artifact)
                    }
                 }
            
            }
            else {
                for (let artifact of toRaw(this.assessmentArtifactList)) {            
                        this.filteredArtifactList.push(reactive(artifact));  
                }          
            }

            this.UserModel.accessType = undefined;
            this.ArtifactModel = {};
            event.preventDefault();
            }
        },
        saveUser() {
            if (this.UserModel.userId == undefined) {
                this.userValidation();
            } else if (this.UserModel.role == undefined) {
                this.userRoleValidation();
            }
            else if (this.UserModel.accessType == undefined) {
                this.artifactTypeValidation();
            } else if (this.ArtifactModel.id == undefined) {
                this.artifactNameValidation();
            }else {
                this.UserModel.isPrimary = 'N';
                this.UserModel.assessmentId = this.ArtifactModel.assessmentId;
                this.AssignUserToArtifact();
            }
            event.preventDefault();

        },
        deleteUserClick(artifactId,userId,role) {
            this.showDeleteModule = !this.showDeleteModule;
            this.deleteModuleStyles = {
                opacity: "0.3",
                id: artifactId, userId: userId, role: role
            };
            // this.deleteSelectedAssessment(assessmentId);
        },
        closePopUp() {
            this.showDeleteModule = false
            this.deleteModuleStyles = {
                opacity: "1",
            };
            this.assessmentUserList(this.assessmentId);
        },
        closeButtonClick() {
            this.$router.push("/searchAssessment");
        },
        validateUserRole(role) {
            if (role == "assessor") {
                return (this.role = "Assessor");
            } else {
                return (this.role = "Reviewer");
            }
        },

        async fetchAssessment(assessmentId) {
            const response = await assessmentService.fetchAssessmentById(assessmentId);
            if (response.data.header.responseMessage === "SUCCESS") {
                this.AssessmentModel = response.data.data;
                sharedService.setData('assessment.model', this.AssessmentModel);
            } else {
                showToast('Failed to fetch Assessment Details', 'error', 'validationerror')
            }
        },

        async AssignUserToArtifact() {
            console.log(this.UserModel);
            // showToast(this.ArtifactModel);
            const response = await userService.assignUserToArtifact(this.UserModel);
            if (response.data.header.responseMessage === "SUCCESS") {
                this.userResponse = response.data.data;
                // sharedService.setData('user.model', this.userResponse)
                this.closeUserPopUp()
            } else {
                showToast("Failed to Assign User to Artifact", 'error', 'validationerror');
            }
        },
        async assessmentUserList(assessmentId) {
            const response = await userService.fetchUserListByAssessmentId(assessmentId)
            console.log("response", response);
            if (response.data.header.responseMessage === "SUCCESS") {
                this.userList = response.data.data
                if (this.userList.length <= 0) {
                    this.showDisplayMessage = true
                    this.tableBodyStyles={
                        display:'flex !important',
                        justifyContent:'center',
                        alignItems:'center',
                    }
                } 
                else {
                    this.showDisplayMessage = false
                    this.tableBodyStyles={
                        display:'block !important',
                    }
                    // this.assessmentUserList(this.assessmentId);
                }
                console.log("UserList", this.userList)
            } else {
                showToast('Failed to User Lisf for this Assessment', 'error', 'validationerror')
            }
        },
        async fetchArtifactList(assessmentId) {
            const response = await artifactService.fetchArtifactListByAssessmentId(assessmentId)
            if (response.data.header.responseMessage === "SUCCESS") {
                this.assessmentArtifactList = response.data.data
                console.log("ArtifactList", this.assessmentArtifactList)

                this.artifactTypes = [...new Set(this.assessmentArtifactList.map(artifact => artifact.artifactType))]
            } else {
                showToast('Failed to fetch ArtifactList For this Assessment', 'error', 'validationerror')
            }

        },
        async fetchAllUser() {
            const response = await userService.fetchUserList();
            if (response.data.header.responseMessage === "SUCCESS") {
                this.fetchUserList = response.data.data;
            }
        },
        async deleteSelectedUser() {
            const response = await userService.deleteUser(this.deleteModuleStyles.id,this.deleteModuleStyles.userId,this.deleteModuleStyles.role);
            //if (response.data.header.responseMessage === "SUCCESS")
            if (response.data.data === "SUCCESS") {
                showToast("User Deleted Successfully", 'success', 'validationsuccess');
                this.closePopUp();
            } else {
                this.closePopUp();
                showToast("Failed to Delete User", 'error', 'validationerror');
            }
        }

    },

};