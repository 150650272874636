<template>
  <div class="container-fluid d-flex flex-column justify-content-center align-items-center ms-5 p-0 position-absolute deletePopup">
    <div class="d-flex flex-column whiteBox">
      <div class="d-flex justify-content-start align-items-center header">
        <span>{{headerMessage}}</span>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center p-5">
        <span class="content">{{message}}</span>
      </div>
      <div class="d-flex justify-content-end p-3 margin150">
          <button  class="btn btn-primary buttonStyle float-end" @click="confirmSelected">
            Yes 
          </button>
          <button  class="btn btn-primary buttonStyle float-end ms-3" @click="cancelSelected">
            No 
          </button>   
        </div>
    </div>
    <div :class="{'overlay':this.serverRequestOn}">
                      </div>
                      <div class="loading-bar-spinner" :class="{'spinner':this.serverRequestOn}">
                        <div class="spinner-icon"></div>
                      </div>
  </div>
</template>
<script src="./popupModule.controller.js"></script>
<style scoped src="./popupModule.style.css"></style>

