import FooterComponent from '@/app/shared/footer/FooterComponent.vue';
import HeaderComponent from '@/app/shared/header/HeaderComponent.vue';
import SideNav from '@/app/shared/side-navigation/SideNav.vue';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
//import { showToast } from '../shared/toast/toast.controller.js';
import ToastComponent from '@/app/shared/toast/ToastComponent.vue';
import { toast } from '../shared/toast/toast.controller.js'
import SharedService from '../services/shared.service.js';
import { ref } from 'vue'
import frameworkService from '@/app/FrameworkDetails/framework.service.js';
//import store from '@/store/index.js';

export default {

    name: 'viewFramework',
    components: { FooterComponent, HeaderComponent, SideNav, ToastComponent, BreadcrumbComponent },
    props: {},

    setup() {
        const showManualScoreError = ref({})
        const loggedInRole = SharedService.getDataFromBrowser('role');
        
        return {
            toastState: toast,
            loggedInRole,
            showManualScoreError
        }
    },
    created() {
        this.frameworkId = this.$route.params.frameworkId;
        this.loadFrameworktoView(this.frameworkId);
        if(this.$store.state.historyPaths.length>0) {
           const prevPathName= this.$store.state.historyPaths[this.$store.state.historyPaths.length-1].name
            if(prevPathName=="frameworkList")
            {
                this.showBreadcrumbs = true;
                this.showCloseButton=true;
            }
        }
        this.$store.commit('addToHistory', this.$route);
        this.breadcrumbStyles = {
            width: '84%'
        }
    },
    beforeUnmount() {
        this.$store.commit("removeFromHistory", this.routePath);
    },
    data() {
        return {
            FrameworkModel:{},
            isActive: false,
            isSubCategoryActive: false,
            /* enableQualityCriteria: true, */
            indexValue: '',
            navigationClick: 'Home',
            frameworkId: '',
            validationMessageStyle: {},
            validationMessageStyleForSubmit: {},
            categoryLineHeight: [],
            subCategoryLineHeight: [],
            qcLineHeight: [],
            catIndex: '',
            questionListLinkEnd: {},
            breadcrumbStyles: {},
            showBreadcrumbs:false,
            showCloseButton:false,
            routePath: '',
            popUpModuleStyles: {},
            serverRequestOn: false,
            showDetails: false,
            validToSaveScore: true,
            currentId: '',
            categoryItems: []
        }

    },
    methods: {
        refilter() {
            this.showDetails = false;
            this.ArtifactModel = {};
            this.enableQualityCriteria = false;
        },
        toggleCategory(item, catIndex, flag) {
            if (item.expanded === undefined) {
                item.expanded = true;
                item.active = true;
                this.isActive = false;
            } else if (item.expanded === true) {
                item.expanded = false;
                item.active = false;
                this.isActive = flag;
            } else if (item.expanded === false) {
                item.expanded = true;
                item.active = true;
                this.isActive = false;
            }
            this.catIndex = catIndex
            setTimeout(() => {
                this.$refs.subCategoryHeight.forEach((_, index) => {
                    this.dynamicSubCatListHeight(index)
                }, 50)
            })
        },
        togglesSubCategory(subCategoryitem, index, catIndex) {
            if (subCategoryitem.expanded === undefined) {
                subCategoryitem.expanded = true;
                subCategoryitem.active = true;
              //  this.isSubCategoryActive = false;
            } else if (subCategoryitem.expanded === true) {
                subCategoryitem.expanded = false;
                subCategoryitem.active = false;
             //   this.isSubCategoryActive = flag;
            } else if (subCategoryitem.expanded === false) {
                subCategoryitem.expanded = true;
                subCategoryitem.active = true;
               // this.isSubCategoryActive = false;
            }
            this.catIndex=catIndex;

            setTimeout(() => {
                this.$refs.qcHeight.forEach((_, index) => {
                    this.dynamicQcListHeight(index)
                }, 50)
            })
            setTimeout(() => this.dynamicSubCatListHeight(index), 100);
        },
        dynamicSubCatListHeight(index) {
            const divSubCategoryElement = this.$refs.subCategoryHeight[index]
            if (divSubCategoryElement) {
                this.subCategoryLineHeight[index] = divSubCategoryElement.clientHeight + 30
            }
            console.log(this.subCategoryLineHeight[index])
            this.dynamicCategoryListHeight(this.catIndex)
        },
        dynamicCategoryListHeight(index) {
            const divCategoryElement = this.$refs.categoryHeight[index]
            if (divCategoryElement) {
                this.categoryLineHeight[index] = divCategoryElement.clientHeight + 30
                console.log(this.categoryLineHeight[index])
            }

        },
        dynamicQcListHeight(index) {
            const divQcElement = this.$refs.qcHeight[index]
            if (divQcElement) {
                this.qcLineHeight[index] = divQcElement.clientHeight + 30
                this.questionListLinkEnd = {
                    margin: '10px 0px 0px 15px'
                }
                console.log(this.qcLineHeight[index])
            }

        },
        homeClick() {
            if (this.navigationClick == "Home") {
                this.$router.push("/landingPage");
            }
        },
        validateFrameworkStatus(status) {
            if (status == "inprogress") {
                return (this.status = "In Progess");
            } else if (status == "submitted") {
                return (this.status = "Submitted");
            } else if (status == "draft") {
                return (this.status = "Draft");
            } else {
                return (this.status = " ");
            }
        },
        async loadFrameworktoView(currentId) {
            const response = await frameworkService.viewFramework(currentId)
            console.log("gotIt", response);
            if (response.status == 200) {
            
                this.FrameworkModel.name=response.data.name;
                this.FrameworkModel._id=response.data.currentId;
                this.FrameworkModel.type=response.data.type;
                this.FrameworkModel.status=this.validateFrameworkStatus(response.data.status);
                this.FrameworkModel.description=response.data.description;
                this.FrameworkModel.clientId=response.data.clientId;
                this.categoryItems = response.data.data.category
                if(this.categoryItems.length>0)
                {
                    setTimeout(() => {
                        this.$refs.categoryHeight.forEach((_, index) => {
                            this.dynamicCategoryListHeight(index)
                        }, 100)
                    });
                }

            }
        },
        closeFrameworkScreen()
        {
            this.$router.push("/frameworkList");
        },
    },

}