<template>
    <HeaderComponent />
    <FooterComponent />
    <SideNav />
    <ToastComponent :visible="toastState.visible" :message="toastState.message" :type="toastState.type" :imageType="toastState.imageType"></ToastComponent>
    <div class="container-fluid p-0">
      <div class="row login-bg m-0">
        <div class="rightContainer d-flex justify-content-end p-0 m-0" :style="deleteModuleStyles">
          <div class="signIn"></div>
          <div class="mx-auto d-flex flex-column justify-content-start align-items-center searchAssessmentBox">
            <div class="d-flex justify-content-start align-items-center mb-3 me-5 breadcrumbsResponsive" :style="breadcrumbStyles">
                  <BreadcrumbComponent />
            </div>
            <div class="row mx-auto d-flex justify-content-center align-items-center ms-5 me-1 mt-2 assessList150" style="width: 95%;">            
              <div class="d-flex justify-content-between align-items-center ms-5 p-0 frameworkListResponsive" style="width: 92%;">
                <div class="headingTextBoxTwo d-flex align-items-center">Framework List</div>
                <button type="search" class="btn btn-primary addNewAssessmentButton" v-on:click="createNewFramework()" v-if=showCreateFrameworkButton>
                  Create Framework
                </button>
              </div>
              <div class="assessmentListTable mt-3 ms-5 p-0">
                <table class="searchAssessmentListTable">
                  <thead>
                    <tr>
                    <th style="border-radius: 10px 0px 0px 0px;">Framework Name</th>
                    <th >Framework Type</th>
                    <th >Status</th>
                    <th style="border-radius: 0px 10px 0px 0px;">Action</th>
                    </tr>
                  </thead>
                  <tbody>

                    <div v-if="loggedInRole==='frameworkAdmin' && showEmptyTableContent" class="d-flex justify-content-center mt-5 mb-5 displayContent" >
                      <span>No Framework to view, continue to create Framework</span>
                    </div>

                    <tr v-for="(framework,index) in frameworkList" :key="index" style="height:2px;" >
                      <td >
                        <span class="p-0">
                          {{ framework.name }}
                        </span>
                      </td>
                      <td>
                        {{ framework.type }}
                      </td>
                      <td :class="validateFrameworkStatus(framework.status)">{{ status }}</td>  
                      <td>
                        <div class="d-flex justify-content-start align-items-center">
                          <span type="search" class="btn editIcon p-0" v-on:click="editFramework(framework)" @mouseover="showToolTip(index,'edit')" @mouseleave="hideToolTip(index,'edit')">
                            <div v-if="showToolTipIndex === index && activeTooltipType==='edit'" class="toolTip">
                                Edit
                            </div>
                          </span>
                          <span type="search" class="btn frameworkIcon p-0" v-on:click="cloneFramework(framework)" @mouseover="showToolTip(index,'clone')" @mouseleave="hideToolTip(index,'clone')">
                            <div v-if="showToolTipIndex === index && activeTooltipType==='clone'" class="toolTip">
                                Clone
                            </div>
                          </span>
                          <span v-if="loggedInRole==='frameworkAdmin'" type="search" class="btn deleteIcon p-0" v-on:click="deleteFramework(framework)" @mouseover="showToolTip(index,'delete')" @mouseleave="hideToolTip(index,'delete')">
                          <div v-if="showToolTipIndex === index && activeTooltipType==='delete'" class="toolTip">
                                Delete
                            </div>
                          </span>
                        </div>
                      </td>
                    </tr>
                </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <delete-module v-if="showDeleteModule" headerMessage="Delete Action" message= "This action will delete the selected Framework and all the artifacts scoring associated with it. Are you sure you want to delete?" @confirm="deleteSelectedFramework" @cancel="closePopUp"></delete-module>
        <InfoPopup v-if="showInfoPopUpModule" headerMessage="Message" message="Unable to delete this Framework already assigned to Artifacts" @ok="closePopUp"></InfoPopup>
      </div>
    </div> 
  </template>
  <script src="./frameworkList.controller.js"></script>
  <style scoped src="./frameworkList.style.css"></style> 