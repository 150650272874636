import FooterComponent from "@/app/shared/footer/FooterComponent.vue";
import HeaderComponent from "@/app/shared/header/HeaderComponent.vue";
import ArtifactList from "@/app/ArtifactDetails/ArtifactList.vue";
import SideNav from "@/app/shared/side-navigation/SideNav.vue";
import { SweetModal, SweetModalTab } from "sweet-modal-vue-3";
import artifactService from '@/app/ArtifactDetails/artifact.service.js';
import assessmentService from '@/app/AssessementDetails/assessment.service.js';
import userService from '@/app/UserDetails/user.service.js';
import { showToast } from '../shared/toast/toast.controller.js';
import { toast } from "../shared/toast/toast.controller.js";
import ToastComponent from "@/app/shared/toast/ToastComponent.vue";
import sharedService from "../services/shared.service.js";
import { toRaw, reactive } from 'vue';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import deleteModule from "../shared/custompopup/PopUpModule.vue";
export default {
    name: "artifactList",
    components: {
        FooterComponent,
        HeaderComponent,
        ArtifactList,
        SideNav,
        SweetModal,
        SweetModalTab,
        BreadcrumbComponent,
        deleteModule,
        ToastComponent
    },
    setup() {
        const loggedInRole = sharedService.getDataFromBrowser("role");

        return {
            toastState: toast,
            loggedInRole,
        };
    },
    props: {
        columns: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            frameworkList: [],
            assessmentId: '',
            assessentName: '',
            assessmentArtifactList: {},
            AssessmentModel: {},
            ArtifactModel: {},
            frameworkTypes: [],
            frameworkListByType: [],
            createArtifactResponse: {},
            errors: {
                artifactName: '',
                artifactTypr: '',
                artifactDescription: ''
            },
            page: 10,
            deleteArtifactId: '',
            breadcrumbStyles: {},
            showDisplayMessage : false,
            showDeleteModule: false,
            emptyArtifact: false,
            deleteModuleStyles: {},           
            viewButtonVisible: {},
            showToolTipIndex:null,
            activeTooltipType:null,
            userList:[],
            frameworkEditFlag:false,
            editFrameworkName:' ',
            readOnlyText:{}
        };
    },
    created() {
        // this.$store.commit('clearHistory');
        this.breadcrumbStyles = {
            width: '90%',
        }
        this.assessmentId = this.$route.params.assessmentId;
        this.AssessmentModel = sharedService.getData('assessment.model');
        if (!this.AssessmentModel) {
            this.fetchAssessment(this.assessmentId);
        }
        this.fetchArtifactUserList(this.assessmentId);
        this.fetchArtifactList(this.assessmentId);
        this.fetchFrameworkList();
        this.readOnlyText={
            color:' #767676; !important',
        }
    },
    methods: {
        
        showToolTip(index,type){
            this.showToolTipIndex=index;
            this.activeTooltipType=type;
        },
        hideToolTip(index,type)
        {
            console.log(index);
            this.showToolTipIndex=null;
            this.activeTooltipType=type;
        },
        saveArtifact() {
            if (this.ArtifactModel.artifactName == undefined) {
                this.artifactNameErrorCheck();
            } else if (this.ArtifactModel.artifactType == undefined) {
                this.artifactTypeErrorCheck();
            } else if (this.ArtifactModel.frameworkId == undefined) {
                this.frameworkNameErrorCheck();
            } else if (this.ArtifactModel.version == undefined) {
                this.artifactVersionErrorCheck();
            }
             else if (!this.ArtifactModel.attachment && this.$refs.file.files.length == 0 && this.frameworkEditFlag) {
                this.selectFile();
            } 
            else {
                // this.$router.push("/searchAssessment");
                this.ArtifactModel.assessmentId = this.assessmentId;
                const formData = new FormData();
                if(this.file!=undefined){
                    formData.append('file', this.file, this.file.name);
                    formData.append("artifact", JSON.stringify(this.ArtifactModel))
                    this.createArtifact(formData);

                }
                else
                {
                    this.updateArtifact(this.ArtifactModel);
                }

            }
            event.preventDefault();
        },
        artifactNameErrorCheck() {
            if (this.ArtifactModel.artifactName == undefined) {
                this.errors.artifactName = 'Please Enter valid Artifact Name';
            } else {
                this.errors.artifactName = '';
            }
        },
        artifactTypeErrorCheck() {
            if (this.ArtifactModel.artifactType == undefined) {
                this.errors.artifactType = 'Please Choose valid Artifact Type';
            } else {
                this.errors.artifactType = '';
                event.preventDefault();
                this.FrameworkModel = [];
                this.frameworkListByType = [];
                for (let framework of toRaw(this.frameworkList)) {
                    if (this.ArtifactModel.artifactType === framework.type && framework.status=== 'submitted') {
                        this.frameworkListByType.push(reactive(framework));
                    }
                }
            }

        },
        selectFile() {
            if (!this.ArtifactModel.attachment && this.$refs.file.files.length == 0) {
                this.errors.attachment = 'Please upload valid document';
            } else {
                this.file = this.$refs.file.files[0];
                if(this.file == undefined){
                    this.errors.attachment = '';
                }
                else if(this.file.size>3000000){
                    this.errors.attachment = 'Please upload a document less than 3mb';
                }
                
                else{
                    this.errors.attachment = '';
                    const dotIndex = this.file.name.lastIndexOf('.');
                    if (dotIndex !== -1) {
                        this.ArtifactModel.attachment = this.file.name.slice(0, dotIndex);
                        this.ArtifactModel.attachmentExtn = this.file.name.slice(dotIndex + 1);
                    } else {
                        this.ArtifactModel.attachment = this.file.name;
                        this.ArtifactModel.attachmentExtn = '';
                    }
            }
                // this.ArtifactModel.attachment = this.file.name;
            }

        },
        frameworkNameErrorCheck() {
            if (this.ArtifactModel.frameworkId == undefined) {
                this.errors.artifactFramework = 'Please choose valid framework';
            } else {
                for(let framework of this.frameworkList)
                {
                    if(framework._id===this.ArtifactModel.frameworkId)
                    {
                        this.ArtifactModel.frameworkActiveStatus = framework.isActive;
                    }
                }
                this.errors.artifactFramework = '';
                this.viewButtonVisible = {
                    visibility: "visible"
                };
            }
        },
        artifactVersionErrorCheck() {
            if (this.ArtifactModel.version == undefined) {
                this.errors.version = 'Please Enter valid Artifact version';
            } else {
                this.errors.version = '';
            }

        },
        checkTextareaLength() {
            console.log(this.$nextTick)
            let descriptionCharacterLength = this.ArtifactModel.artifactDescription
            if (descriptionCharacterLength === undefined) {
                descriptionCharacterLength = 0
            } else {
                descriptionCharacterLength = descriptionCharacterLength.length
            }
            this.$nextTick(() => {
                if (descriptionCharacterLength >= 100) {
                    this.errors.artifactDescription = 'Please Enter valid Description less than 100 characters';
                } else {
                    this.errors.artifactDescription = ''
                }
            })
        },
        assessmentList() {
            if (this.assessmentId) {
                this.$router.push({ name: "editAssessment", params: { assessmentId: this.AssessmentModel.assessmentId } });
            } else {
                showToast("Assessment is not created", 'error', 'validationerror');
            }
        },
        assessmentUserList() {
            if (this.assessmentId && this.emptyArtifact) {
                this.$router.push({ name: "userList", params: { assessmentId: this.assessmentId } });
            } else {
                showToast("Artifact is not created", 'error', 'validationerror');
            }

        },
        addArtifact() {
            this.ArtifactModel = {
                artifactStatus:'draft'
            };
            this.file = undefined;
            this.$refs.modal.open();
            this.frameworkEditFlag=true;
            this.viewButtonVisible = {
                visibility: "hidden"
            };
        },
        closeArtifactUpload() {
            this.$refs.modal.close();
            this.fetchArtifactList(this.assessmentId);
            this.$refs.file.value = '';
            this.viewButtonVisible = {
                visibility: "hidden"
            };
            this.errors = [];
        },
        editArtifact(artifactDetails) {
            let artifactUser =this.userList.map((user)=>  {
                if(user.artifactId == artifactDetails.id) return user;
            })
            if(artifactUser && artifactDetails.artifactStatus!='draft')
            {
                this.frameworkEditFlag=false;
                let frameworkName =null;
                for(let framework of this.frameworkList)  {
                    if(framework._id == artifactDetails.frameworkId)
                    { 
                        frameworkName= framework.name;
                    } 
                }
                this.editFrameworkName=frameworkName;
/*                 let abc = this.editFrameworkName.join(', ')
                abc.replace

                console.log("AFTER COMMA REMOVE", this.editFrameworkName) */
            }
            else
            {
                this.frameworkEditFlag=true;
            }
            this.ArtifactModel = artifactDetails;
            this.artifactTypeErrorCheck();
            this.file = undefined;
            this.viewButtonVisible = {
                visibility: "visible"
            };
            this.$refs.modal.open();
        },
        deleteButton(artifactId) {

            // this.deleteArtifactId = artifactId
            this.showDeleteModule = !this.showDeleteModule;
            this.deleteModuleStyles = {
                opacity: "0.3",
                id: artifactId
            };
            // this.deleteSelectedAssessment(assessmentId);
        },
        closePopUp() {
            this.showDeleteModule = false
            this.deleteModuleStyles = {
                opacity: "1",
            };
            this.fetchArtifactList(this.assessmentId);
        },
        openFileDialog() {
            event.preventDefault();
            let fileInputElement = this.$refs.file;
            fileInputElement.click();
        },
        nextClick() {
            if (this.emptyArtifact) {
                this.$router.push({ name: "userList", params: { assessmentId: this.assessmentId } });
            } else {
                showToast('Artifact is not created', 'error', 'validationerror')
            }
        },
        artifactStatus(status) {
            if (status == "inprogress") {
                return (this.status = "In Progess");
            } else if (status == "submitted") {
                return (this.status = "Submitted");
            } else if (status == "draft") {
                return (this.status = "Draft");
            } else if(status == "approved") {
                return (this.status = "Approved");
            }
            else if(status == "rejected") {
                return (this.status = "Rejected");
            }
            else
            {
                return (this.status = "Unknown");
            }
        },
        viewFramework() {
            const routerData = this.$router.resolve({ name: "viewFramework", params: { frameworkId: this.ArtifactModel.frameworkId } });
           window.open(routerData.href, 'blank');
       
        },
        async fetchAssessment(assessmentId) {
            const response = await assessmentService.fetchAssessmentById(assessmentId);
            if (response.data.header.responseMessage === "SUCCESS") {
                this.AssessmentModel = response.data.data;
                sharedService.setData('assessment.model', this.AssessmentModel);
            } else {
                showToast('Failed to fetch Assessment Details', 'error', 'validationerror')
            }
        },
        async fetchArtifactList(assessmentId) {
            const response = await artifactService.fetchArtifactListByAssessmentId(assessmentId)
            if (response.data.header.responseMessage === "SUCCESS") {
                this.assessmentArtifactList = response.data.data
                if (this.assessmentArtifactList.length <= 0) {
                    this.showDisplayMessage = true
                    this.tableBodyStyles={
                        display:'flex !important',
                        justifyContent:'center',
                        alignItems:'center',
                    }
                    this.emptyArtifact = false;
                } else {
                    this.showDisplayMessage = false
                    this.tableBodyStyles={
                        display:'block !important',
                    }
                    this.emptyArtifact = true
                }
                console.log("ArtifactList", this.assessmentArtifactList)
            } else {
                showToast('Failed to fetch ArtifactList For this Assessment', 'error', 'validationerror')
            }

        },
        async fetchArtifactUserList(assessmentId)
        {
            const response = await userService.fetchUserListByAssessmentId(assessmentId)
            console.log("response", response);
            if (response.data.header.responseMessage === "SUCCESS") {
                this.userList = response.data.data
                
                console.log("UserList", this.userList)
            } else {
                showToast('Failed to User List for this Assessment', 'error', 'validationerror')
            }
        },
        async fetchFrameworkList() {
            const response = await artifactService.fetchFrameworkList()
            if (response.data.header.responseMessage === "SUCCESS") {
                this.frameworkList = response.data.data
                console.log("frameworkKist", this.frameworkList)
                this.frameworkTypes = [...new Set(this.frameworkList.map(framework => framework.type))]
                console.log('frameworkType', this.frameworkTypes);
            } else {
                showToast('Failed to fetch frameworkList ', 'error', 'validationerror')
            }

        },
        async createArtifact(formData) {
            this.serverRequestOn = true;
            const response = await artifactService.saveArtifact(formData);

            this.serverRequestOn = false;
            if (response.data.header.responseMessage === "SUCCESS") {
                this.createArtifactResponse = response.data.data;
                sharedService.setData('artifact.model', this.createArtifactResponse)
                this.closeArtifactUpload()
            } else {
                showToast("Failed to Create Artifact", 'error', 'validationerror');
            }

        },

        async updateArtifact(artifact) {
            this.serverRequestOn = true;
            artifactService.updateArtifact(artifact).then(updatedArtifactResponse=>{
                this.serverRequestOn = false;
                if (updatedArtifactResponse.data.header.responseMessage === "SUCCESS") {
                    this.createArtifactResponse = updatedArtifactResponse.data.data;
                    sharedService.setData('artifact.model', this.createArtifactResponse)
                    this.closeArtifactUpload()
                } else {
                    showToast("Failed to update Artifact", 'error', 'validationerror');
                }    
            });

            
        },
        async deleteSelectedArtifact() {
            this.serverRequestOn = true;
            const response = await artifactService.deleteArtifact(this.deleteModuleStyles.id);
            this.serverRequestOn = false;
            //if (response.data.header.responseMessage === "SUCCESS")
            if (response.data.data === "SUCCESS") {
                showToast("Artifact Delete Success", 'success', 'validationsuccess');
                this.closePopUp();
            } else {
                showToast("Failed to Delete Artifact", 'error', 'validationerror');
                this.closePopUp();
            }
        }
    },
};