<template>
  <HeaderComponent />
  <FooterComponent />
  <SideNav />
  <ToastComponent :visible="toastState.visible" :message="toastState.message" :type="toastState.type" :imageType="toastState.imageType"></ToastComponent> 
   <div class="container-fluid p-0">
    <div class="row login-bg m-0">
      <div class="rightContainer d-flex justify-content-end p-0 m-0" :style="popUpModuleStyles">
        <div class="signIn"></div>
        <div class="mx-auto d-flex flex-column justify-content-start align-items-center assessmentScoreScreen">
          <div class="row p-0 d-flex justify-content-start align-items-center" :style="breadcrumbStyles">
                <BreadcrumbComponent />
            </div>
<!--             <ol class="breadcrumb" :style="breadcrumbStyles">
              <li @click="homeClick()">Home</li>
              <li class="active">Artifact Scoring</li>
            </ol> -->
          <div class="row mx-auto d-flex justify-content-center align-items-center ms-5 me-1 mb-5 mt-4" style="width: 95%">
            <div class="headingTextBox d-flex align-items-center ms-5">Assessment - {{assessmentName}}</div>
            <div class="whiteBox ms-5" id="whiteBoxOne">
              <!-- <div class="subHeadingText mt-3 mb-3"></div> -->
              <form class="row g-3 p-0">
                <div class="d-flex justify-content-between align-items-center mt-4 p-0">
                  <div class="d-flex justify-content-start align-items-center" style="width: 90%;">
                    <div class="col-4">
                      <label for="artifactsType" class="form-label createAssessmentLabel">Artifacts Type</label>
                      <div v-if="!showDetails" class="dropdownSelect">
                        <select v-model="ArtifactModel.artifactType" id="artifactType" class="dynamicDropdown" :value="value" ref= 'input' v-on:input="loadArtifactNameByType($event)">
                          <option disabled selected value="undefined" class="dropdownOption">Select Artifact Type</option>
                          <option v-for="artifact in artifactTypes" :key="artifact.artifactType" :value="artifact" selected>
                            {{ artifact}}
                          </option>
                        </select>
                        <div v-if="artifactTypeError" class="validationMessage">
                          {{ artifactTypeError }}
                        </div>
                      </div>
                      <div class="col-4 d-flex flex-column" v-if="showDetails">
                          <div class="scoringPlaceHolder">
                            <input v-model="this.ArtifactModel.artifactType" name="artifactType" type="text" readonly class="fontSize150"/>
                          </div>
                       </div>
                    </div>
                    <div class="col-4">
                      <label for="artifactsName" class="form-label createAssessmentLabel">Artifact Name</label>
                      <div v-if="!showDetails" class="dropdownSelect">
                        <select v-model="ArtifactModel" id="artifactsName" class="dynamicDropdown" :value="value" ref="input" v-on:input="setArtifactFlags()" :disabled="!ArtifactModel.artifactType">
                          <option disabled selected value="undefined" class="dropdownOption">Select Artifact Name</option>
                          <option :value="artifact" v-for="artifact in artifactListByType" :key="artifact"  selected>
                            {{ artifact.artifactName }}
                          </option>
                        </select>
                        <div v-if="artifactNameError" class="validationMessage">
                          {{ artifactNameError }}
                        </div>
                      </div>
                      <div class="col-4 d-flex flex-column" v-if="showDetails">
                          <div class="scoringPlaceHolder">
                            <input v-model="this.ArtifactModel.artifactName" name="artifactName" type="text" readonly class="fontSize150"/>
                          </div>
                          </div>
                    </div>
                      <div class="col-2 d-flex justify-content-start align-items-baseline" style="margin-bottom:-1em">
                        <span type="search" class="btn downloadIcon"></span>
                        <a class="downloadOption" 
                        v-bind:href="ArtifactModel.artifactUrl"
                        >Download</a>
                      </div>
                  </div>
                </div>
              </form>
              <div class="d-flex justify-content-end align-items-center">
                <div class="d-flex justify-content-end align-items-center" style="width: 15%;margin-top: 1.5em;">
                    <button v-if="!showDetails" type="search" class="btn btn-primary searchButton" v-on:click="enableSearchClick(ArtifactModel)">
                      Search
                    </button>
                    <button v-if="showDetails" type="search" class="btn btn-primary searchButton" v-on:click="refilter()">
                      Re-filter
                    </button>
                </div>
              </div>
            </div>
            <div class="headingTextBox d-flex align-items-center ms-5 mt-5" v-if="enableQualityCriteria">
              <div class="d-flex justify-content-between align-items-center" style="width:100%">
                <span>Scoring Section </span>
                <span :class="artifactStatus(ArtifactModel.artifactStatus)">Artifact Status: {{ status }}</span>
            </div>
            </div>
            <div class="whiteBox ms-5 mb-5" style="padding: 1.5em 0.5em 2em 0em;" id="whiteBoxTwo" v-if="enableQualityCriteria">             
              <div class="d-flex justify-content-start align-items-start fontSize150">
                <ul class="mainCategoryList" style="min-width:55%">
                  <div class="d-flex flex-column"> 
                    <div v-if="throwSubmitError" class="validationScoreMessage" :style="validationMessageStyleForSubmit">
                      {{throwSubmitErrorMsg}}
                    </div>
                    <div v-if="throwSaveLaterError" class="validationScoreMessage" :style="validationMessageStyleForSubmit">
                      {{throwSaveLaterErrorMsg}}
                    </div>
                    <li v-for="(category,index) in artifactScoreModel.data.category" :key="category.catId" class="subCategoryListItems" ref="categoryHeight">
                      <div class="d-flex align-items-center">
                        <div class="d-flex flex-column align-items-center">
                          <div @click="toggleCategory(category, index,!isActive)" :class="{'categoryMaximiseIcon':!category.active,'categoryMinimiseIcon':category.active}" >
                            <div class="categoryLinkLine" :style="{height:categoryLineHeight[index] + 'px'}">
                          </div>
                          </div>
                          <div class="d-flex flex-column align-items-center <!-- lineControl -->" v-for="category in artifactScoreModel.data.categoryList" :key="category.catId">
                          </div>
                        </div>
                        <div class="col-12 d-flex align-items-center ms-2">
                          <div class="col-4 d-flex align-items-center">
                            <span class="mainCategoryQuestionContent" id="activeCategory">
                              &nbsp; 
                              {{ category.name }}
                              &nbsp;
                            </span>
                          </div>
                          <div class="col-9 d-flex">
                            <ul class="col 12 subCategoryList">
                              <div class="d-flex  align-items-center">
                                <li v-for="subCategory in category.subCategory" :key="subCategory.id">
                                  <span class="subCategoryQuestionContent">
                                    &nbsp; {{ subCategory.name }} : {{ subCategory.qc.length }} 
                                  </span>
                                </li>
                                <div class="lineSeparator"></div>
                                <li>
                                  <span class="subCategoryQuestionContent">weight : {{ category.weight }}</span>
                                </li>
                              </div>
                              
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex align-items-center">
                        <ul class="col-12 subCategoryList">
                          <div class="d-flex flex-column ms-3" v-if="category.expanded">
                            <li v-for="(subCategory,index) in category.subCategory" :key="subCategory.subCatId" style="margin-top: 2em;" ref="subCategoryHeight">
                              <div class="d-flex align-items-center mt-3">
                                <div @click="togglesSubCategory(subCategory,index,!isSubCategoryActive)" :class="{'subCategoryMaximiseIcon':!subCategory.active,'subCategoryMinimiseIcon':subCategory.active}">
                                  <div class="linkLine" :style="{height:subCategoryLineHeight[index] + 'px'}">
                                  </div>
                                </div>
                                <div class="d-flex justify-content-between align-items-center">
                                  <div class="d-flex align-items-center">
                                    <span class="questionsContent" style="padding-right: 2em;">
                                      &nbsp; {{ subCategory.name }}
                                    </span>
                                  </div> 
                                  <div class="lineSeparator">
                                  </div>
                                  <div class="d-flex align-items-center">
                                    <span class="questionsContent" style="padding-left: 2em;">
                                      Weight :{{ subCategory.weight }}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <ul class="questionListItem" v-if="subCategory.expanded">
                                <div class="d-flex flex-column ms-4 mt-3">
                                  <li v-for="(questionList,index) in subCategory.qc" :key="questionList.id" style="margin-top: 1em;" ref="qcHeight">
                                    <div class="d-flex align-items-center">
                                      <div class="d-flex align-items-center">
                                        <div class="questionListIcon">
                                          <div class="qcLinkLine" :style="{height:qcLineHeight[index] + 'px'}">
                                          </div>
                                        </div>
                                        <span class="questionsContent">
                                          QC 0{{index+1}} :
                                        </span>
                                      </div>
                                      &nbsp; {{ questionList.name }}
                                    </div>
                                    <br>
                                    <div class="d-flex align-items-center ms-4">
                                      <span class="questionsContent">
                                        How to Evaluate Quality :
                                      </span>&nbsp; {{ questionList.evaluate }}
                                    </div>
                                    <br>
                                    <div class="d-flex align-items-center ms-4">
                                      <span class="questionsContent">
                                        Scoring Guidelines :
                                      </span>
                                      &nbsp; {{ questionList.guideline }}
                                    </div>
                                    <br>
                                    <div class="d-flex justify-content-between align-items-baseline ms-4">
                                      <div class="d-flex align-items-center">
                                        <span class="questionsContent">
                                          Weight :
                                        </span>
                                        &nbsp; {{ questionList.weight }}
                                      </div>
                                      <div class="d-flex flex-column ms-4">
                                        <span class="questionsContent">
                                          Manual Score :
                                        </span>
                                        <input v-if="!viewScore" type="number" class="manualScore" v-model="questionList.score" @blur="checkScore(questionList)" onfocus="this.value=''"> 
                                        <input v-if="viewScore" type="number" class="manualScore" v-model="questionList.score" @blur="checkScore(questionList)" readonly>                                        
                                          <div v-if="!viewScore && questionList.parentId" class="validationMessage" :style="validationMessageStyle">
                                            {{manualScoreError}}
                                          </div> 
                                      </div>
                                      <div class="d-flex flex-column ms-4">
                                        <span class="questionsContent">
                                          Assessor Observations :
                                        </span>
                                        <textarea v-if="!viewScore" type="text" class="descriptionField" v-model="questionList.assessorComment"
                                          placeholder="Type the Observation text here…" onfocus="this.placeholder = ''"
                                          onblur="this.placeholder = 'Type the Observation text here…'" maxlength="100" >
                                          </textarea>
                                          <textarea v-if="viewScore" type="text"  class="descriptionField" v-model="questionList.assessorComment"
                                          placeholder="Type the Observation text here…" onfocus="this.placeholder = ''"
                                          onblur="this.placeholder = 'Type the Observation text here…'" maxlength="100" readonly>
                                          </textarea>
                                          <div v-if="!viewScore && checkLength" class="validationMessage">
                                            {{inputTextError}}
                                          </div>
                                      </div>
                                      <div  v-if="this.ArtifactModel.artifactStatus=='submitted' && loggedInRole==='reviewer'" class="d-flex flex-column" style="padding-left: 2em;" >
                                        <span class="questionsContent" >
                                          Reviewer Observations :
                                        </span>
                                        <textarea v-if="!viewScore" type="text" class="descriptionField" v-model="questionList.reviewerComment"
                                          placeholder="Type the Observation text here…" onfocus="this.placeholder = ''"
                                          onblur="this.placeholder = 'Type the Observation text here…'" maxlength="100" readonly>
                                          </textarea>
                                          <textarea v-if="viewScore" type="text"  class="descriptionField" v-model="questionList.reviewerComment"
                                          placeholder="Type the Observation text here…" onfocus="this.placeholder = ''"
                                          onblur="this.placeholder = 'Type the Observation text here…'" maxlength="100">
                                          </textarea>
                                          <div v-if="!viewScore && checkLength" class="validationMessage">
                                            {{inputTextError}}
                                          </div>
                                      </div>
                                      <div  v-if="this.ArtifactModel.artifactStatus=='rejected' && loggedInRole==='frameworkAdmin'" class="d-flex flex-column" style="padding-left: 2em;">
                                        <span class="questionsContent" >
                                          Reviewer Observations :
                                        </span>
                                        <textarea v-if="!viewScore" type="text" class="descriptionField" v-model="questionList.reviewerComment"
                                          placeholder="Type the Observation text here…" onfocus="this.placeholder = ''"
                                          onblur="this.placeholder = 'Type the Observation text here…'" maxlength="100" readonly>
                                          </textarea>                              
                                          <textarea v-if="viewScore" type="text"  class="descriptionField" v-model="questionList.reviewerComment"
                                          placeholder="Type the Observation text here…" onfocus="this.placeholder = ''"
                                          onblur="this.placeholder = 'Type the Observation text here…'" maxlength="100" readonly>
                                          </textarea>
                                          <div v-if="!viewScore && checkLength" class="validationMessage">
                                            {{inputTextError}}
                                          </div>
                                      </div>
                                      <div  v-if="this.ArtifactModel.artifactStatus=='rejected' && (loggedInRole==='reviewer' || loggedInRole==='assessor')" class="d-flex flex-column" style="padding-left: 2em;" >
                                        <span class="questionsContent" >
                                          Reviewer Observations :
                                        </span>
                                        <textarea v-if="!viewScore" type="text" class="descriptionField" v-model="questionList.reviewerComment"
                                          placeholder="Type the Observation text here…" onfocus="this.placeholder = ''"
                                          onblur="this.placeholder = 'Type the Observation text here…'" maxlength="100" readonly>
                                          </textarea>                                          
                                          <textarea v-if="viewScore" type="text"  class="descriptionField" v-model="questionList.reviewerComment"
                                          placeholder="Type the Observation text here…" onfocus="this.placeholder = ''"
                                          onblur="this.placeholder = 'Type the Observation text here…'" maxlength="100">
                                          </textarea>
                                          <div v-if="!viewScore && checkLength" class="validationMessage">
                                            {{inputTextError}}
                                          </div>
                                      </div>
                                        <div  v-if="this.ArtifactModel.artifactStatus=='approved' && (loggedInRole==='assessor' || loggedInRole==='reviewer' || loggedInRole==='frameworkAdmin')" class="d-flex flex-column" style="padding-left: 2em;" >
                                        <span class="questionsContent" >
                                          Reviewer Observations :
                                        </span>
                                        <textarea v-if="!viewScore" type="text" class="descriptionField" v-model="questionList.reviewerComment"
                                          placeholder="Type the Observation text here…" onfocus="this.placeholder = ''"
                                          onblur="this.placeholder = 'Type the Observation text here…'" maxlength="100" readonly>
                                          </textarea>
                                          <textarea v-if="viewScore" type="text"  class="descriptionField" v-model="questionList.reviewerComment"
                                          placeholder="Type the Observation text here…" onfocus="this.placeholder = ''"
                                          onblur="this.placeholder = 'Type the Observation text here…'" maxlength="100" readonly>
                                          </textarea>                                          
                                          <div v-if="!viewScore && checkLength" class="validationMessage">
                                            {{inputTextError}}
                                          </div>
                                      </div>
                                    </div>
                                  </li>
                                  <div class="linkLineEnd" :style="questionListLinkEnd"></div>
                                </div>
                              </ul>

                            </li>
                            <div class="linkLineEnd">
                                      
                            </div>
                          </div>
                        </ul>
                      </div>
                    </li>
                    <div class="linkLineEnd">
                                      
                    </div>
                  </div>
                </ul>             
                 <div class="d-flex justify-content-end align-items-center" style="position: absolute;width: 88%;z-index:10"> 
                  <div class="d-flex justify-content-between align-items-center" >                    
                    <button type="search" class="btn btn-primary d-flex justify-content-between align-items-center saveLaterButton me-4" @click="artifactDashboard(ArtifactModel.id)">
                      <div class="dashboardIcon"></div>
                      <div class="fontSize150">Dashboard</div>
                    </button>
                </div>
                 </div>
              
              </div>
              <div v-if="loggedInRole==='frameworkAdmin'" class="d-flex justify-content-end align-items-end mt-5">
                  <button type="reset" class="btn btn-primary saveLaterButton me-4 fontSize150" v-on:click="closeScore()">
                    <div>Close</div>
                  </button>
                </div>
                <div v-if="loggedInRole==='assessor'" class="d-flex justify-content-end align-items-end mt-5">
                  <button type="reset" class="btn btn-primary saveLaterButton me-4" v-on:click="closeScore()">
                        <div class="fontSize150">Close</div>
                    </button>
                  <button type="reset" class="btn btn-primary saveLaterButton me-4" v-if="this.ArtifactModel.artifactStatus!='submitted' && this.ArtifactModel.artifactStatus!='approved'" v-on:click="saveForLater()">
                        <div class="fontSize150">Save For Later</div>
                    </button>
                    <button type="reset" class="btn btn-primary submitButton me-4" v-if="this.ArtifactModel.artifactStatus!='submitted' && this.ArtifactModel.artifactStatus!='approved'" v-on:click="submitApproval()">
                        <div class="fontSize150">Submit for Approval</div>
                    </button>
                </div>
                <div v-if="loggedInRole==='reviewer'" class="d-flex justify-content-end align-items-end mt-5">
                  <button type="reset" class="btn btn-primary saveLaterButton buttonWithoutIcon me-4" v-on:click="closeScore()">
                        <div>Close</div>
                      </button>
                   <button type="reset"  v-if="this.ArtifactModel.artifactStatus=='submitted'" class="btn btn-primary saveLaterButton buttonWithoutIcon me-4" v-on:click="saveForLater()">
                        <div>Save For Later</div>
                  </button>
                  <button type="reset" v-if="this.ArtifactModel.artifactStatus=='submitted'" class="btn btn-primary d-flex align-items-center resetButton me-4" v-on:click="rejectApproval()">
                        <div class="rejectIcon"></div>
                        <div>Reject</div>
                    </button>
                    <button type="reset" v-if="this.ArtifactModel.artifactStatus=='submitted'" class="btn btn-primary d-flex align-items-center saveLaterButton me-4" v-on:click="artifactApproval()" >
                        <div class="approveIcon"></div>
                        <div>Approve</div>
                    </button>

                </div>
            </div>
          </div>
        </div>
      </div>
      <PopUpModule v-if="showPopUpModule" headerMessage="Confirm Action" message="Are you sure you want to submit this Artifact for Approval?" @confirm="submitArtifact" @cancel="closePopUp"></PopUpModule>
      <PopUpModule v-if="showPopUpModuleReject" headerMessage="Confirm Action" message="Are you sure you want to reject the artifact?" @confirm="rejectArtifact" @cancel="closePopUp"></PopUpModule>
      <PopUpModule v-if="showApprovePopUpModule" headerMessage="Confirm Action" message="Are you sure you want to approve the artifact?" @confirm="approveArtifact" @cancel="closePopUp"></PopUpModule>
      <InfoPopup v-if="showAlreadySubPopUpModule && loggedInRole==='assessor'" headerMessage="Message" message="Already submitted for Approval" @ok="closePopUp"></InfoPopup>
      <InfoPopup v-if="showAlreadyRejPopUpModule && loggedInRole==='assessor'" headerMessage="Message" message="This artifact is Rejected by Reviewer" @ok="closePopUp"></InfoPopup>
      <InfoPopup v-if="showAlreadyAppPopUpModule" headerMessage="Message" message="Already this artifact is approved" @ok="closePopUp"></InfoPopup>
      <div :class="{'overlay':this.serverRequestOn}">
      </div>
      <div class="loading-bar-spinner" :class="{'spinner':this.serverRequestOn}">
      <div class="spinner-icon"></div>
                      </div>
    </div>
  </div>

  <!--  </div> -->
</template>
<script src="./AssessmentScoring.contoller.js"></script>
<style scoped src="./AssessmentScoring.style.css"></style>
  