import axios from '../shared/interceptor.js';
import apiEndpoint from '@/app/services/uri-constants.js';

export default {

    async fetchUserListByAssessmentId(assessmentId) {
        const response = await axios.get(`${apiEndpoint.userListByAssessmentId}` + assessmentId)
        return response;

    },
    async assignUserToArtifact(UserAccess) {
        const response = await axios.post(`${apiEndpoint.assignUserToArifact}`, UserAccess)
        return response;

    },

    async fetchUserList() {
        const response = await axios.get(`${apiEndpoint.fetchUser}`)
        return response;
    },
    async deleteUser(artifactId, userId, role) {
        const response = await axios.delete(`${apiEndpoint.deleteAssignedUser}` + artifactId + '&userId=' + userId +'&role=' +role)
        return response;
    }

}