
<template>
  <!--   <div class="header-section">
    <div class="header-content">
      <div>
        <span>Welcome</span>
        <span>Framework Admin</span>
      </div>
      <nav>
        <router-link to="/">About</router-link>|
        <router-link to="/login">Login</router-link>
      </nav>
    </div>
  </div> -->
  <nav id="navbar" :style="headerLogoStyles">
    <div class="nav-wrapper rqdHeader">
      <div class="d-flex justify-content-between align-items-center headerLogo">
        <div class="rqdLogo">
          <a href="#"><img src="../../../assets/img/rqdHeaderLogo.svg"></a>
        </div>
        <div class="logoSeparator">

        </div>
        <div class="logo">
          <a href="#"><img src="../../../assets/img/NTTHeaderLogo.svg"></a>
        </div>
      </div>
    </div>
    <div class="headerList d-flex justify-content-between align-items-center">
      <div class="d-flex flex-column justify-content-between align-items-end" style="width: 11em;">
        <span>
          {{ userName }}
        </span>
        <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-center align-items-center changeRole" @click="toggleDropdown">
              <div class="activeRoleIcon"></div>
              <div> {{ currentRole.label }}</div>
            </div>
            <div class="user-info">
              <svg @click="toggleDropdown" viewBox="0 0 1030 638" width="10">
                <path d="M1017 68L541 626q-11 12-26 12t-26-12L13 68Q-3 49 6 24.5T39 0h952q24 0 33 24.5t-7 43.5z"
                  fill="#FFF" style="cursor: pointer;"></path>  
              </svg>
              <nav v-if="showDropdown" class="userOptions">
                <div class="d-flex flex-column">
                  <div class="d-flex justify-content-end align-items-center">
                    <div class="menu-item">
                    </div>
                  </div>
                  <ul class="list-unstyled pe-2">
                    <!-- <li class="selected-menu">{{ currentRole }}</li> -->
                    <li>
                      <div class="d-flex justify-content-start align-items-center ms-2 mt-2" v-if="additionalRoles.length > 0" @click="toggleRoleDropdown">
                        <div class="d-flex justify-content-between align-items-center">                                
                          <svg  viewBox="0 0 1030 638" width="15">
                            <path d="M1017 68L541 626q-11 12-26 12t-26-12L13 68Q-3 49 6 24.5T39 0h952q24 0 33 24.5t-7 43.5z" fill="black" style="cursor: pointer;"></path>  
                          </svg>
                          <div class="ms-2">
                            Change Role 
                          </div> 
                        </div>
                      </div>
                      <ul class="list-unstyled" id="selectedRole" v-if ="showRoleDropdown">                         
                        <li v-for="role in additionalRoles" :key="role" @click="changeRole(role)">
                          <div v-if="role.value!=currentRole.value" class="d-flex justify-content-start align-items-center">
                            <div>
                              <div class="inActiveRoleIcon"></div>
                            </div>
                            <div >
                                {{ role.label }} 
                            </div>
                          </div>                       
                        </li>
                      </ul>
                    </li>
                    <!-- Logout button -->
                    <!-- <li class="selected-menu" @click="logout">Logout</li> -->
                    <li>
                          <div class="d-flex justify-content-start align-items-center" @click="logout">
                            <div class="logout"></div>
                            <div class="position-relative z-5">Logout</div>
                          </div>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="userIcon"></div>
        <!-- <div class="logout" @click="logout()"></div> -->
      </div>
    </div> 
  </nav>
  <LogoutModule v-if="showPopUpModule" headerMessage="Logout Action" message="Are you sure you want to logout?" @confirm="logout" @cancel="closePopUp"></LogoutModule>

  <FrameworkAdmin />
</template>
<script src="./header.controller.js"></script>

<style  src="./header.style.css"></style>