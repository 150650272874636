import FooterComponent from '@/app/shared/footer/FooterComponent.vue';
import HeaderComponent from '@/app/shared/header/HeaderComponent.vue';
import SearchAssessment from '@/app/AssessementDetails/SearchAssessment.vue';
import SideNav from '@/app/shared/side-navigation/SideNav.vue';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import { showToast } from '../shared/toast/toast.controller.js';
import ToastComponent from '@/app/shared/toast/ToastComponent.vue';
import { toast } from '../shared/toast/toast.controller.js'
import assessmentService from '@/app/AssessementDetails/assessment.service.js';
import SharedService from '../services/shared.service.js';
import { sharedVariable } from '../services/sharedData.js'
import Paginate from "vuejs-paginate-next";
import sharedService from '../services/shared.service.js';
import popModule from "../shared/custompopup/PopUpModule.vue";
import frameworkService from '../FrameworkDetails/framework.service';

export default {
    name: "createFramework",
    components: {
        FooterComponent,
        HeaderComponent,
        SearchAssessment,
        BreadcrumbComponent,
        SideNav,
        paginate: Paginate,
        ToastComponent,
        popModule,
    },
    props: {
        columns: {
            type: Array,
            required: true,
        },
    },
    setup() {
        const loggedInRole = SharedService.getDataFromBrowser("role");
        sharedVariable.value = window.location.pathname;
        return {
            toastState: toast,
            loggedInRole,
        };
    },

    data() {
        return {
            page: 10,
            showDefaultContent: true,
            showFirstSearchBox: true,
            showSecondSearchBox: false,
            clientList: [],
            frameworkList: [],
            frameworkId: '',
            frameworkTypes: ["Usecase", "BRD", "SEZ"],
            ClientModel: {},
            FrameworkModel: {
                status: 'draft',
            },
            type: ['category', 'subCategory','qc'],
            FrameworkRecord: {},
            showAssessmentSearchFilter: true,
            showCreateFrameworkButton: true,
            clientError: "",
            navigationClick: "Home",
            breadcrumbStyles: {},
            showBreadcrumbs: false,
            showDeleteModule: false,
            showConfirmPopUp:false,
            deleteModuleStyles: {},
            showToolTipDelete: false,
            errors: {
                frameworkName: '',
                frameworkType: '',
                frameworkDescription: '',
            },
            categoryLineHeight: [],
            subCategoryLineHeight: [],
            qcLineHeight: [],
            enableAddCategory: false,
            isActive: false,
            isCatActive: true,
            isSubCatActive: true,
            isQcActive: false,
            isCategoryActive: false,
            isSubCategoryActive: false,
            isQuestionCriteriaActive: false,
            category: [],
            showFrameworkBox: false,
            showFrameworkRecords: false,
            addCategoryErrorMessage: '',
            validToSaveScore:false,
            addSubCategoryButton: false,
            textareaContent:'',
            tooltipStyle:{},
        };
    },

    created() {
        this.breadcrumbStyles = {
            width: "83.75%",
        };
        //this.$store.commit("clearHistory");
        //  this.$store.commit("addToHistory", this.$route);
        this.frameworkId = this.$route.params.frameworkId;
        this.$store.commit("addToHistory", this.$route);
        if (this.frameworkId) {
            this.routePath = "/editFramework";
            this.FrameworkModel = sharedService.getData("framework.model");
            this.editFrameworkAPI(this.frameworkId,0,0);
            //this.editAssessment = true;
        }
            this.onLoadClientName();
        
            this.$store.commit("addToHistory", this.$route);
    },
    beforeUnmount() {
        this.$store.commit("removeFromHistory", this.routePath);
    },
    methods: {
        tooltipStyle(){
            return{
                border:'2px solid green',
                borderRadius:'5px',
                padding:'8px',
                width:'200px',
                height:'300px',
            }
        },
        validateFrameworkStatus(status) {
            if (status == "inprogress") {
                return (this.status = "In Progess");
            } else if (status == "submitted") {
                return (this.status = "Submitted");
            } else if (status == "draft") {
                return (this.status = "Draft");
            } else {
                return (this.status = " ");
            }
        },
        createNewFramework() {
            this.$router.push("/createFramework");
        },
        closePopUp() {
            this.showDeleteModule = false
            this.showConfirmPopUp=false;
            this.deleteModuleStyles = {
                opacity: "1",
            };
        },
        checkTextareaLength() {
            console.log(this.$nextTick)
            let descriptionCharacterLength = this.FrameworkModel.frameworkDescription
            if (descriptionCharacterLength === undefined) {
                descriptionCharacterLength = 0
            } else {
                descriptionCharacterLength = descriptionCharacterLength.length
            }
            this.$nextTick(() => {
                if (descriptionCharacterLength >= 250) {
                    this.errors.frameworkDescription = 'Please Enter valid Description less than 100 characters';
                } else {
                    this.errors.frameworkDescription = ''
                }
            })
        },
        frameworkNameValidation() {
            if (this.FrameworkModel.name == undefined) {
                this.errors.frameworkName = "Please enter the Framework name";
            } else {
                this.errors.frameworkName = " ";
            }
        },
        frameworkTypeValidation() {
            if (this.FrameworkModel.type == undefined) {
                this.errors.frameworkType = "Please select Framework type";
            } else {
                this.errors.frameworkType = " ";
            }
        },
        reFilterClick() {
            this.showFirstSearchBox = true;
            console.log(this.FrameworkModel);
            this.showSecondSearchBox = false;
            event.preventDefault();
        },
        addNewCategory(createdRecord) {
            /* this.isCategoryActive = !this.isCategoryActive */
            if (this.showFrameworkRecords) {
                this.category.push({
                    subCategory: [],
                    type: 'category',
                    _id: createdRecord._id,
                    expanded: true,
                    active:true
                });
                this.isCategoryActive = true
                this.addSubCategoryButton = true
                if (this.isCategoryActive == true) {
                    this.addCategoryErrorMessage = "";

                } else {
                    this.validationMessageStyle = {
                        marginLeft: '8%'
                    }
                    this.addCategoryErrorMessage = "Expand the section to add a new Category"
                }
                this.showFrameworkRecords = false;
            }
            showToast("New Category added successfully", "success", 'validationsuccess');
        },
        addNewSubCategory(catIndex, createdRecord) {
            this.category[catIndex].subCategory.push({
                qc: [],
                _id: createdRecord._id,
                expanded: true,
                active:true
            });
            showToast("New Subcategory added successfully", "success", 'validationsuccess');
        },
        addNewQuestionCriteria(catIndex, subCatIndex, createdRecord) {
            /* this.isQuestionCriteriaActive = !this.isQuestionCriteriaActive */
            // if (this.isQuestionCriteriaActive == false) {
            //     this.isQuestionCriteriaActive = true
            // }

            this.category[catIndex].subCategory[subCatIndex].qc.push({
                _id: createdRecord._id,
                expanded: true,
                active:true
            });
            showToast("New Quality Criteria added successfully", "success", 'validationsuccess');
        },
        
        scrollToTop() {
            
            window.scrollTo(0, 0);
        },
        toggleCategory(item, index, flag) {
            if (item.expanded === undefined) {
                item.expanded = true;
                item.active = true;
                this.isCatActive = false;
            } else if (item.expanded === true) {
                item.expanded = false;
                item.active = false;
                this.isCatActive = flag;
            } else if (item.expanded === false) {
                item.expanded = true;
                item.active = true;
                this.isCatActive = flag;
            }
            // this.active = !this.active
            // console.log("INDEX FOR CATEGORY", this.category[index])
            // this.isQuestionCriteriaActive = false
            this.catIndex = index;
            if(item.subCategory.length>0){
            setTimeout(() => this.dynamicCategoryListHeight(index), 100);
            setTimeout(() => {
                this.$refs.subCategoryHeight.forEach((_, index) => {
                    this.dynamicSubCatListHeight(index)
                }, 50)
            })
        }
        },
        toggleSubCategory(subCategoryitem, subCatindex, flag) {
            if (subCategoryitem.expanded === undefined) {
                subCategoryitem.expanded = true;
                subCategoryitem.active = true;
                this.isSubCategoryActive = false;
            } else if (subCategoryitem.expanded === true) {
                subCategoryitem.expanded = false;
                subCategoryitem.active = false;
                this.isSubCategoryActive = flag;
            } else if (subCategoryitem.expanded === false) {
                subCategoryitem.expanded = true;
                subCategoryitem.active = true;
                this.isSubCategoryActive = flag;
            }
            setTimeout(() => this.dynamicSubCatListHeight(subCatindex), 100);
            // setTimeout(() => {
            //     this.$refs.qcHeight.forEach((_, subCatindex) => {
            //         this.dynamicQcListHeight(subCatindex)
            //     }, 50)
            // })
        },
        dynamicSubCatListHeight(index) {
            const divSubCategoryElement = this.$refs.subCategoryHeight[index]
            if (divSubCategoryElement) {
                this.subCategoryLineHeight[index] = divSubCategoryElement.clientHeight + 30
            }
            console.log(this.subCategoryLineHeight[index])
            this.dynamicCategoryListHeight(this.catIndex)
        },
        dynamicCategoryListHeight(index) {
            const divCategoryElement = this.$refs.categoryHeight[index]
            if (divCategoryElement) {
                this.categoryLineHeight[index] = divCategoryElement.clientHeight + 30
                console.log(this.categoryLineHeight[index])
            }

        },
        dynamicQcListHeight(index) {
            const divQcElement = this.$refs.qcHeight[index]
            if (divQcElement) {
                this.qcLineHeight[index] = divQcElement.clientHeight + 30
                this.questionListLinkEnd = {
                    margin: '10px 0px 0px 15px'
                }
                console.log(this.qcLineHeight[index])
            }

        },
        async onLoadClientName() {
            assessmentService.fetchClient().then(response => {
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.clientList = response.data.data;
                } else {
                    showToast("Failed to fetch Clients", "error", 'validationerror');
                }
            });
        },
        createFramework() {
            if (this.FrameworkModel.name == undefined) {
                this.frameworkNameValidation();
            } else if (this.FrameworkModel.type == undefined) {
                this.frameworkTypeValidation();
            } else {
                this.createFrameworkAPI();
            }
            event.preventDefault();
        },
        createFrameworkAPI() {
            console.log(this.FrameworkModel);
            frameworkService.createFramework(this.FrameworkModel).then(response => {
                    console.log("response in controller--", response);
                    if (response.data.header.responseMessage === "SUCCESS") {
                        // sharedService.setData("client.model", this.ClientModel);'
                        this.FrameworkModel.name=response.data.data.name;
                        this.FrameworkModel._id=response.data.data._id;
                        this.FrameworkModel.type=response.data.data.type;
                        this.FrameworkModel.status=response.data.status;
                        this.FrameworkModel.description=response.data.data.description;
                        this.FrameworkModel.clientId=response.data.data.clientId;
                        sharedService.setData("framework.model", this.FrameworkModel);
                        this.frameworkId = this.FrameworkModel._id;
                        showToast("Framework created successfully", "success", 'validationsuccess');
                        this.showFirstSearchBox = false;
                        this.showFrameworkBox = true;
                        this.showSecondSearchBox = true;
                        this.enableAddCategory = true;
                    } else {
                        showToast('Failed to save framework', 'error', 'validationerror');
                    }
                })
                .catch(err => {
                    // Handle errors
                    console.error('error in controller--', err);
                });

        },
        addCategory(catIndex, subCatIndex, type, parentId) {
            let validToCreate = true;
            if (this.category.length > 0) {
                this.category.totalCatWeight = 0
                for (let cat of this.category) {
                    this.category.totalCatWeight = this.category.totalCatWeight + cat.weight;
                    if ((cat.weight > 100) || (cat.weight <= 0)) {
                        this.throwSaveLaterError = true
                        this.validationMessageStyleForSubmit = {
                            margin: '0% 0% 0% 1% !important',
                            fontSize: '15px !important'
                        }
                        cat.errorMsg = "Please enter the values between 1 to 100"
                    }
                    else
                    {
                        cat.errorMsg=" ";
                    }
                }
                if (this.category.totalCatWeight >= 100) {
                    this.category.catErrorMsg = " Category total weight cannot exceed 100";
                    validToCreate = false;
                } else {
                    this.category.catErrorMsg = " ";
                }

            }
            this.createFrameworkRecordAPI(catIndex, subCatIndex, type, parentId, validToCreate);
            setTimeout(() => this.dynamicCategoryListHeight(catIndex), 100);
         
        },
        addSubCategory(catIndex, subCatIndex, type, parentId) {
            let validToCreate = true;
            let cat = this.category[catIndex];
            if (cat.subCategory.length > 0) {
                cat.totalSubCatWeight = 0
                for (let subCat of cat.subCategory) {
                    cat.totalSubCatWeight = cat.totalSubCatWeight + subCat.weight;
                    if ((subCat.weight > 100) || (subCat.weight <= 0)) {
                        this.throwSaveLaterError = true
                        this.validationMessageStyleForSubmit = {
                            margin: '0% 0% 0% 1% !important',
                            fontSize: '15px !important'
                        }
                        subCat.errorMsg = "Please enter the values between 1 to 100"

                    }
                    else{
                        subCat.errorMsg = "";
                    }
                }
                if (cat.totalSubCatWeight >= 100) {
                    cat.subCatErrorMsg = "Sub Category total weight cannot exceed 100";
                    validToCreate = false;
                } else {
                    cat.subCatErrorMsg = "";
                }

            }
            this.createFrameworkRecordAPI(catIndex, subCatIndex, type, parentId, validToCreate);
            this.dynamicSubCatListHeight(subCatIndex);
        },
        addQc(catIndex, subCatIndex, type, parentId) {
            let validToCreate = true;
            let subCat = this.category[catIndex].subCategory[subCatIndex];
            if (subCat.qc.length > 0) {
                subCat.totalQcWeight = 0
                for (let qc of subCat.qc) {
                    subCat.totalQcWeight = subCat.totalQcWeight + qc.weight;
                    if ((qc.weight > 100) || (qc.weight <= 0)) {
                        this.throwSaveLaterError = true
                        this.validationMessageStyleForSubmit = {
                            margin: '0% 0% 0% 1% !important',
                            fontSize: '15px !important'
                        }

                        qc.errorMsg = "Please enter the values between 1 to 100"

                    }
                    else
                    {
                        qc.errorMsg = "";
                    }
                }
                if (subCat.totalQcWeight >= 100) {
                    subCat.qcErrorMsg = "Quality Criteria total weight cannot exceed 100";
                    validToCreate = false;
                } else {
                    subCat.qcErrorMsg = "";
                }
            }
            this.createFrameworkRecordAPI(catIndex, subCatIndex, type, parentId, validToCreate);
        },

        createFrameworkRecordAPI(catIndex, subCatIndex, type, parentId, validToCreate) {
            if (validToCreate) {
                this.FrameworkRecord.type = type
                this.FrameworkRecord.parentId = parentId;
                this.FrameworkRecord.frameworkId = this.FrameworkModel._id;
                frameworkService.createFrameworkRecord(this.FrameworkRecord).then(response => {
                        console.log("response in controller--", response);
                        if (response.data.header.responseMessage === "SUCCESS") {
                            this.showFrameworkRecords = true;
                            if (type == 'category') {
                                this.addNewCategory(response.data.data)
                               // this.dynamicCategoryListHeight(catIndex);
                            }
                            if (type == 'subCategory') {
                                this.addNewSubCategory(catIndex, response.data.data);
                                this.catIndex=catIndex;
                                this.dynamicSubCatListHeight(subCatIndex) 
                            }
                            if (type == 'qc') {
                                this.addNewQuestionCriteria(catIndex, subCatIndex, response.data.data)
                                this.catIndex=catIndex;
                                this.dynamicSubCatListHeight(subCatIndex) 
                            }

                        } else {
                            showToast('Failed to Create framework record', 'error', 'validationerror');
                        }
                    })
                    .catch(err => {
                        // Handle errors
                        console.error('error in controller--', err);
                    });
            }

        },
        editFrameworkAPI(frameworkId,catIndex,subCatIndex) {
            frameworkService.editFramework(frameworkId).then(response => {
                    console.log("response in controller--", response);
                    if (response.data.data.category.length > 0) {
                        this.category = response.data.data.category;
                        console.log(this.category);
                        if(!catIndex){
                            catIndex=0;
                        }
                        if(!subCatIndex)
                        {
                            subCatIndex=0;
                        }
                        this.toggleCategory(this.category[catIndex],catIndex,false);
                        if(this.category[catIndex].subCategory.length>0){
                            this.toggleSubCategory(this.category[catIndex].subCategory[subCatIndex],subCatIndex,false)
                        }
                        showToast("Framework Data pull successfully", "success", 'validationsuccess');
                        setTimeout(() => {
                            
                            this.$refs.categoryHeight.forEach((_, index) => {
                                this.dynamicCategoryListHeight(index)
                            }, 100)
                        })


                    } else {

                        // showToast('Failed to fetch framework', 'error', 'validationerror');
                    }
                    if(!this.FrameworkModel)
                    {
                        this.FrameworkModel={};
                    }
                    this.FrameworkModel.name=response.data.name;
                    this.FrameworkModel._id=response.data.currentId;
                    this.FrameworkModel.type=response.data.type;
                    this.FrameworkModel.status=response.data.status;
                    this.FrameworkModel.description=response.data.description;
                    this.FrameworkModel.clientId=response.data.clientId;
                    this.showFirstSearchBox = false;
                    this.showSecondSearchBox = true;
                    this.showFrameworkBox = true;
                    this.enableAddCategory = true;
                    this.addSubCategoryButton = true;
                    SharedService.setData("framework.model", this.FrameworkModel);
           
                    
                    // this.isSubCategoryActive = true;
                    /* this.active= true; */
                })
                .catch(err => {
                    // Handle errors
                    console.error('error in controller--', err);
                });

        },
        updateFrameworkStatus(status)
        {
            frameworkService.updateFrameworkStatus(this.frameworkId,status).then(response => {
                if (response.data.header.responseMessage === "SUCCESS") {
                    showToast("Framework Submitted successfully", "success", 'validationsuccess');
                //    this.scrollToTop();
                    // this.editFramework(this.frameworkId);
                } else {
                    showToast('Failed to Submit framework ', 'error', 'validationerror');
                }
            })
            .catch(err => {
                // Handle errors
                console.error('error in controller--', err);
            });

        },
        deleteSelectedFrameworkRecord(id, type,cIndex,scIndex) {
            this.scrollToTop();
            this.showDeleteModule = !this.showDeleteModule;
            this.deleteModuleStyles = {
                opacity: "0.3",
                id: id,
                type:type,
                catIndex:cIndex,
                subCatIndex:scIndex
            };
        },
        deleteFrameworkRecordApi() {
            this.saveForLater(false);
            frameworkService.deleteFrameworkRecord(this.deleteModuleStyles.id, this.deleteModuleStyles.type).then(response => {
                    //if (response.data.header.responseMessage === "SUCCESS")
                    if (response.data.data === "SUCCESS") {
                        console.log(response.data.data);
                        this.closePopUp();
                        if(this.FrameworkModel.status==='submitted' || this.FrameworkModel.status==='Submitted'){
                           this.updateFrameworkStatus("inprogress");
                        }
                        this.editFrameworkAPI(this.frameworkId,this.deleteModuleStyles.catIndex,this.deleteModuleStyles.subCatIndex);
                    } else {
                        showToast("Failed to Delete Assessment", 'error', 'validationerror');
                        this.closePopUp();
                    }
                })
                .catch(err => {
                    // Handle errors
                    console.error('error in controller--', err);
                });
        },
        saveAllFrameworkDetails(saveAllFrameworkData,toastFlag) {
            frameworkService.saveAllFrameworkDetails(saveAllFrameworkData).then(response => {
                    console.log("response in controller--", response);
                    if (response.data.header.responseMessage === "SUCCESS") {
                        if(toastFlag){
                        showToast("Framework Details saved successfully", "success", 'validationsuccess');
                        }
                        this.scrollToTop();
                        // this.editFramework(this.frameworkId);
                        if(this.FrameworkModel.status=='draft' || this.FrameworkModel.status=='Draft')
                        {
                            this.updateFrameworkStatus('inprogress');
                        }
                        if(this.validToSaveScore){
                            this.showConfirmPopUp = !this.showConfirmPopUp;
                            this.deleteModuleStyles = {
                                opacity: "0.3",
                            };
                        }

                    } else {
                        showToast('Failed to Create framework record', 'error', 'validationerror');
                    }
                })
                .catch(err => {
                    // Handle errors
                    console.error('error in controller--', err);
                });
        },
        closeFrameworkScreen()
        {
            this.$router.push("/frameworkList");
        },
        saveForLater(toastFlag) {
            var saveAllFrameworkData=[];
            var frameworkData = {}
            var frameworkSubCatData={};
            var frameworkQcData={};
            console.log(this.category)
            for (let category of this.category) {
                frameworkData = {};
                frameworkData._id = category._id;
                frameworkData.name = category.name;
                frameworkData.weight = category.weight;
                frameworkData.description = category.description
                frameworkData.type = this.type[0];
                frameworkData.parentId = this.FrameworkModel._id;
                frameworkData.frameworkId = this.FrameworkModel._id;
                saveAllFrameworkData.push(frameworkData);
                if (category.subCategory.length > 0) {
                    for (let subCat of category.subCategory) {
                        frameworkSubCatData = {}
                        frameworkSubCatData._id = subCat._id;
                        frameworkSubCatData.name = subCat.name;
                        frameworkSubCatData.weight = subCat.weight;
                        frameworkSubCatData.description = subCat.description
                        frameworkSubCatData.type = 'subCategory';
                        frameworkSubCatData.parentId = category._id;
                        frameworkSubCatData.frameworkId = this.FrameworkModel._id;
                        saveAllFrameworkData.push(frameworkSubCatData);

                        if (subCat.qc.length > 0) {
                            for (let question of subCat.qc) {
                                frameworkQcData = {}
                                frameworkQcData._id = question._id;
                                frameworkQcData.name = question.name; //quality creteria
                                frameworkQcData.weight = question.weight;
                                frameworkQcData.evaluate = question.evaluate;
                                frameworkQcData.guideline = question.guideline;
                                frameworkQcData.type = 'qc'; //category = qc
                                frameworkQcData.parentId = subCat._id;
                                frameworkQcData.frameworkId = this.FrameworkModel._id;
                                saveAllFrameworkData.push(frameworkQcData);
                            }
                        }
                    }
                }
            }
            this.saveAllFrameworkDetails(saveAllFrameworkData,toastFlag);
        },
        submitFramework()
        {

            this.updateFrameworkStatus('submitted');
            this.closePopUp();
           
        },

        submitFrameworkValidation(toastFlag) {
          //  this.saveLater=false;
            this.catTotalWeight = 0;

            this.category.forEach(cat => {
                this.catTotalWeight = this.catTotalWeight + cat.weight;
                cat.totalSubCatWeight = 0;
                cat.subCategory.forEach(subCat => {
                    cat.totalSubCatWeight = cat.totalSubCatWeight + subCat.weight;
                    subCat.totalQcWeight=0;
                    subCat.qc.forEach(qc => {
                        subCat.totalQcWeight=subCat.totalQcWeight+qc.weight;
                    });
                    if ( (this.catTotalWeight > 100) || (cat.totalSubCatWeight > 100) || (subCat.totalQcWeight > 100) ) {
                        this.throwSaveLaterError = true
                        this.validationMessageStyleForSubmit = {
                            margin: '0% 0% 0% 1% !important',
                            fontSize: '15px !important'
                        }

                        this.validToSaveScore = false;
                        this.category.catErrorMsg = "Total weight cannot exceed 100";

                    }
                    if ((this.catTotalWeight <= 0) || (cat.totalSubCatWeight <= 0) || (subCat.totalQcWeight <= 0)) {
                        this.throwSaveLaterError = true
                        this.validationMessageStyleForSubmit = {
                            margin: '0% 0% 0% 1% !important',
                            fontSize: '15px !important'
                        }

                        this.validToSaveScore = false;
                        this.category.catErrorMsg = "Total weight should be equal to 100";

                    }
                    if ((this.catTotalWeight == 100) && (cat.totalSubCatWeight == 100) && (subCat.totalQcWeight == 100)) {
                        this.validToSaveScore=true;
                        this.category.catErrorMsg=" "
                    }
    

                });
            });

            this.saveForLater(toastFlag);
            if(!this.validToSaveScore)
            {
                showToast(" Validation Failed ", "error", 'validationerror');
            }
        },

    },
};