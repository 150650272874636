// let deleteOptionSeleted = Boolean
export default {
  name: 'deleteModule',
  data() {
      return {
          /* deleteOptionSeleted: false */
      }
  },
  props: {
      headerMessage: String,
      message: String,
  },
  methods: {
      okSelected() {
          this.$emit('ok'); 
      }
  }
}
// const confirmDelete = () => {
//     const deleteOptionValue = deleteOptionSeleted
//     console.log(deleteOptionValue)
//     return deleteOptionValue
// }

// export {
//     confirmDelete
// }